import MatAutocomplete, {
  AutocompleteProps as MatAutocompleteProps,
  AutocompleteRenderInputParams,
} from "@mui/material/Autocomplete"

import React, { FC, useState, SyntheticEvent } from "react"

import { InputField, FieldVariant } from "src/components/common/inputfield"

import { SelectOption } from "src/components/common/select"
import { TranslationMessages } from "src/translations"

export type AutoCompleteProps = Omit<
  MatAutocompleteProps<
    SelectOption | undefined,
    undefined,
    undefined,
    undefined
  >,
  "renderInput"
> & {
  name: string
  required?: boolean
  errorText?: string
  messages?: TranslationMessages
  helperText?: string
  label?: string
  variant?: FieldVariant
  onValidate?: (e: SyntheticEvent, error?: string) => void
}

export const Autocomplete: FC<AutoCompleteProps> = ({
  name,
  value,
  variant,
  label,
  required,
  options = [],
  errorText,
  messages,
  helperText,
  onChange,
  onValidate,
  ...props
}) => {
  const [inputValue, setInputValue] = useState<string>(value?.value ?? "")

  const handleValidation = (e: SyntheticEvent, item?: SelectOption | null) => {
    const value = item?.value
    if (!onValidate) {
      return null
    }
    if (required && !value) {
      return onValidate(
        e,
        messages?.components?.common?.fields?.required ??
          "This field is required!",
      )
    }
    return onValidate(e, undefined)
  }
  if (options?.length > 0) {
    return (
      <MatAutocomplete
        {...props}
        autoHighlight
        filterSelectedOptions
        inputValue={inputValue}
        isOptionEqualToValue={(option, value) => option?.key === value?.key}
        options={options}
        value={value ?? null}
        getOptionLabel={(option?: SelectOption | string) =>
          typeof option === "string" ? option : option?.value ?? ""
        }
        renderInput={(params: AutocompleteRenderInputParams) => (
          <InputField
            {...params}
            errorText={errorText}
            helperText={helperText}
            label={label}
            name={name}
            required={required}
            variant={variant}
          />
        )}
        onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
        onChange={(e, item, reason) => {
          handleValidation?.({ ...e, type: name }, item)
          onChange?.({ ...e, type: name }, item, reason)
        }}
      />
    )
  }
  return null
}
