import { Locale } from "src/translations";
import { PagesProps } from "src/types/SharedPageProps";

export type PageTemplates =
  | "Account Overview Template"
  | "Booking Overview Template"
  | "Booking Detail Template"
  | "Booking Form Template"
  | "Co-Exhibitor Booking Form Template"
  | "Co-Exhibitor Booking View Template"
  | "Webshop Overview Template"
  | "Webshop Product Detail Template"
  | "Webshop Standrequests Overview Template"
  | "Webshop Incidents Template"
  | "Webshop Orders Template"
  | "Webshop Order Detail Template"
  | "Webshop Cart Overview Template"
  | "Webshop Cart Checkout Template"

export const getPage = (template: PageTemplates, locale: Locale, pages: PagesProps) => pages.data.nodes.filter(page =>
	page.language?.slug === locale && page.template?.templateName === template,
)?.[0]