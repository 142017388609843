import ChevronRight from "@mui/icons-material/ChevronRight"
import ExpandMore from "@mui/icons-material/ExpandMore"

import MatTreeView, {
  TreeViewProps as MatTreeViewProps,
} from "@mui/lab/TreeView"

import React, { FC } from "react"

import { TreeViewItem, TreeViewItemProps } from "./TreeViewItem"

export type TreeViewProps = MatTreeViewProps & {
  enableSwitch?: boolean
  items: TreeViewItemProps[]
  onClick?: (item: TreeViewItemProps) => void
}

export const TreeView: FC<TreeViewProps> = ({
  enableSwitch,
  items,
  onClick,
  ...props
}) => {
  if (items && items?.length === 0) {
    return null
  }
  return (
    <MatTreeView
      defaultCollapseIcon={<ExpandMore />}
      defaultExpandIcon={<ChevronRight />}
      {...props}
      onClick={() => undefined}
    >
      {items.map((item, i) => (
        <TreeViewItem
          {...item}
          key={item?.nodeId ?? i?.toString()}
          enableSwitch={enableSwitch}
          nodeId={item?.nodeId ?? i?.toString()}
          onClick={onClick}
        />
      ))}
    </MatTreeView>
  )
}

TreeView.displayName = "TreeView"
