import { Window } from "src/types/global/gtm/gtm"

export const pushGtmStep = (
  activeStep: number,
  stepName: string | undefined | null,
  formName: string,
) => {
  ;(window as unknown as Window)?.dataLayer?.push({
    event: "funnel",
    activeStep: activeStep,
    stepName: stepName,
    formName: formName,
  })
}
