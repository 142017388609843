import { format } from "date-fns"
import { enGB, de } from "date-fns/locale"

export const isIsoDate = (e: string) => {
  if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(e)) return false
  var d = new Date(e)
  return d.toISOString() === e
}

export const formatLocaleDate = (
  lang: string,
  date: Date | string | number,
  dateFormat?: string,
) => {
  let locale = enGB
  if (lang === "de") {
    locale = de
  }
  return format(new Date(date), dateFormat ?? "dd/M/yyyy  hh:mm", {
    locale,
  })
}
