import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined"
import CheckCircleIcon from "@mui/icons-material/CheckCircleOutline"
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined"
import StorefrontIcon from "@mui/icons-material/StorefrontOutlined"
import SupportAgentIcon from "@mui/icons-material/SupportAgentOutlined"
import LinearProgress from "@mui/material/LinearProgress"
import moment from "moment"
import React, { FC, ReactNode, useState } from "react"

import { Accordion } from "src/components/common/accordion"
import { Button } from "src/components/common/button"
import { Card } from "src/components/common/card"
import { Link } from "src/components/common/link"

import { create } from "src/helpers/bem"

import { postPlacement } from "src/queriesXRM/placement"

import { useBooking } from "src/states/bookingDetail"
import { mergeBookingDetailPlacementReject } from "src/states/bookingDetailPlacementReject"
import { useLocale } from "src/states/locale"

import { TranslationMessages } from "src/translations"

import { xRMApiPlacement, xRMApiPlacementStatus } from "src/types/xRM"

import { loadPlacements } from "./BookingDetail"

import styles from "./BookingDetailPlacement.module.scss"

const bem = create(styles, "BookingDetailPlacement")

export type BookingDetailPlacementProps = {
  messages: TranslationMessages
}

export const BookingDetailPlacement: FC<BookingDetailPlacementProps> = ({
  messages,
}) => {
  const [loading, setLoading] = useState(false)
  const m = messages.pages.booking.detail.placement
  const locale = useLocale()
  const { placements } = useBooking()

  const onAcceptPlacement = async (placement: xRMApiPlacement) => {
    setLoading(true)
    await postPlacement({
      id: placement?.id as string,
      placementStatus: "Accept",
    })
    await loadPlacements()
    setLoading(false)
  }

  const renderAccordion = (
    id?: string | null,
    status?: xRMApiPlacementStatus,
    defaultExpanded?: boolean,
    title?: string,
    children?: NonNullable<ReactNode>,
  ) => {
    let icon = <SupportAgentIcon className={bem("icon")} fontSize="small" />
    switch (status) {
      case "Proposed":
        icon = <StorefrontIcon className={bem("icon")} fontSize="small" />
        break
      case "Accept":
        icon = <CheckCircleIcon className={bem("icon")} fontSize="small" />
        break
      case "Reject":
        icon = <StorefrontIcon className={bem("icon")} fontSize="small" />
        break
      case "Expired":
        icon = <CancelOutlinedIcon className={bem("icon")} fontSize="small" />
        break
    }
    const modifier = {
      "is-disabled": !defaultExpanded,
    }
    return (
      <Accordion
        className={bem("accordion")}
        defaultExpanded={defaultExpanded}
        detailsClassName={bem("accordion__details", modifier)}
        id={id as string}
        classNames={{
          wrapper: bem("accordion__summary"),
        }}
        label={
          <div className={bem("title__wrapper")}>
            <div className={bem("title__icon", modifier)}>{icon}</div>
            <span className={bem("title", modifier)}>{title}</span>
          </div>
        }
      >
        <div
          className={bem("content", {
            ...modifier,
            "has-border-left": status !== "Accept",
          })}
        >
          {children}
        </div>
      </Accordion>
    )
  }

  const renderFutureStep = (
    id?: string | null,
    title?: string,
    content?: string,
  ) =>
    renderAccordion(
      id,
      undefined,
      true,
      title,
      <div className={bem("description")}>{content}</div>,
    )

  const RenderPlacement = (index: number, data: xRMApiPlacement) => {
    let status = data?.placementStatus
    if (!status || status === "Draft") {
      return null
    }
    const t = m?.[status]
    if (!t) {
      return null
    }
    const firstItem = data?.id === placements?.[0]?.id && status !== "Reject"
    const modifier = {
      "is-disabled": status === "Expired",
    }
    return (
      <>
        {status === "Reject" && index === 0
          ? renderFutureStep(
              data?.id + "FutureStep",
              t?.futureStep?.title,
              t?.futureStep?.content,
            )
          : null}
        {renderAccordion(
          data?.id,
          status,
          firstItem,
          t.title,
          <>
            <div className={bem("wrapper", modifier)}>
              <div className={bem("meta")}>
                <p>{t.meta}</p>
              </div>
              <div className={bem("placement")}>
                <div className={bem("placement__location")}>
                  {data?.location}
                </div>
                <Link
                  href={data?.placementDocumentLink ?? "#"}
                  target="_blank"
                  classNames={{
                    link: bem("placement__link"),
                    label: bem("placement__link__label"),
                  }}
                >
                  <FileDownloadOutlinedIcon
                    className={bem("placement__link__icon")}
                    fontSize="small"
                  />
                  <span className={bem("placement__link__text")}>
                    {m.download}
                  </span>
                </Link>
              </div>
              {data?.description && (
                <div className={bem("meta")}>
                  <p>{data.description}</p>
                </div>
              )}
              {t?.content && (
                <div className={bem("description")}>
                  <p>{t.content}</p>
                </div>
              )}
              {t?.expiresAt && data?.expiryDate ? (
                <div className={bem("expiresAt")}>
                  <p>
                    {t.expiresAt}{" "}
                    <b className={bem("expiresAt__date")}>
                      {moment(data.expiryDate).format("L")}
                    </b>
                  </p>
                </div>
              ) : null}
              {data?.updatedBy &&
              (status === "Accept" || status === "Reject") ? (
                <div className={bem("info")}>
                  {locale === "en" ? (
                    <p>
                      {t?.info1}{" "}
                      <span className={bem(`info__status__${status}`)}>
                        {t?.info2}
                      </span>{" "}
                      {t?.info3} {data?.updatedBy} {t?.info4}{" "}
                      {moment(data?.updatedOn).format("L")}.
                    </p>
                  ) : (
                    <p>
                      {t?.info1} {moment(data?.updatedOn).format("L")}{" "}
                      {t?.info2} {data?.updatedBy}{" "}
                      <span className={bem(`info__status__${status}`)}>
                        {t?.info3}
                      </span>
                      .
                    </p>
                  )}
                </div>
              ) : null}
            </div>
            {data?.contact && status === "Expired" ? (
              <div
                className={bem("meta", {
                  "is-highlighted": true,
                })}
              >
                <p>
                  {t.contact1} {data.contact} {t.contact2}
                </p>
              </div>
            ) : null}
            {status === "Proposed" && (
              <>
                <Button
                  className={bem("button")}
                  color="success"
                  title={t.buttons?.right}
                  onClick={() => onAcceptPlacement(data)}
                />
                <Button
                  className={bem("button")}
                  color="error"
                  title={t.buttons?.left}
                  onClick={() =>
                    mergeBookingDetailPlacementReject({
                      id: data?.id as string,
                      open: true,
                    })
                  }
                />
              </>
            )}
          </>,
        )}
      </>
    )
  }

  if (!placements || placements?.length === 0) {
    return null
  }
  return (
    <div className={bem()}>
      <Card>
        {loading ? (
          <LinearProgress color="primary" />
        ) : (
          placements.map((placement, i) => (
            <React.Fragment key={`placement-${i}`}>
              {RenderPlacement(i, placement)}
            </React.Fragment>
          ))
        )}
      </Card>
    </div>
  )
}
