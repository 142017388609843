import CloseIcon from "@mui/icons-material/Close"
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined"
import LinearProgress from "@mui/material/LinearProgress"
import { useRouter } from "next/router"
import React, { FC, useEffect, useMemo, useState } from "react"

import { Button } from "src/components/common/button"
import { Drawer } from "src/components/common/drawer"
import { BasicLayoutStyleProps } from "src/components/layouts/basic"

import { ShopCartProductCard } from "src/components/services/shop/cart/ShopCartProductCard"
import { create } from "src/helpers/bem"

import { getPage } from "src/helpers/pages"
import { Dialogs, mergeDialog, useDialog } from "src/states/dialogs"
import { useLocale } from "src/states/locale"
import {
  useCarts,
  useCart,
  postRefreshCart,
  setCart,
} from "src/states/shop/carts"
import { TranslationMessages } from "src/translations"

import { PagesProps } from "src/types/SharedPageProps"

import styles from "./ShopCartDialog.module.scss"

const bem = create(styles, "ShopCartDialog")

export type ShopCartDialogProps = {
  messages: TranslationMessages
  pages: PagesProps
  style: BasicLayoutStyleProps
}

export const ShopCartDialog: FC<ShopCartDialogProps> = ({
  messages,
  pages,
  style,
}) => {
  const { loading: loadingCarts } = useCarts()
  const cart = useCart()
  const locale = useLocale()
  const router = useRouter()
  const { open } = useDialog('cartDialog') as Dialogs['cartDialog']
  const [loading, setLoading] = useState(false)
  const m = messages.pages.shop.cart.dialog

  useEffect(() => {
    setLoading(true)
    const refreshCartAsync = async () => setCart(await postRefreshCart(cart))
    refreshCartAsync().finally(() => setLoading(false))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale])

  const buttonDisabled =
    !cart.products || cart.products?.length === 0 || loadingCarts || loading

  const webshopCartOverviewPage = useMemo(() => getPage("Webshop Cart Overview Template", locale, pages),[pages, locale])

  const webshopCartCheckoutPage = useMemo(() => getPage("Webshop Cart Checkout Template", locale, pages),[pages, locale])

  return (
    <Drawer
      anchor="right"
      open={open}
      style={style}
      onClose={() => mergeDialog('cartDialog', { open: false })}
      onOpen={() => mergeDialog('cartDialog', { open: true })}
    >
      {loadingCarts && <LinearProgress color="primary" />}
      <div className={bem()}>
        <div className={bem("header")}>
          <div className={bem("counter")}>
            <ShoppingBagOutlinedIcon className={bem("counter__icon")} />
            <h3 className={bem("counter__title")}>
              {cart.products?.length ?? 0}{" "}
              {cart.products && cart.products?.length > 1 ? m.title2 : m.title1}
            </h3>
          </div>
          <button
            className={bem("close")}
            onClick={() => mergeDialog('cartDialog', { open: false })}
          >
            <CloseIcon className={bem("close__icon")} />
          </button>
        </div>
        <div className={bem("products")}>
          {cart.products && cart.products.length > 0 ? cart.products.map(product =>
            <ShopCartProductCard
							key={product.productVariant?.id}
							item={product}
						/>) : <div> </div>}
        </div>
        <div className={bem("footer")}>
          <Button
            className={bem("button")}
            disabled={buttonDisabled}
            title={m.buttons.checkout}
            variant="contained"
            onClick={async () => {
              mergeDialog('cartDialog', { open: false })
              await router.push({
                pathname: webshopCartCheckoutPage?.uri as string,
                query: {
                  cartId: cart.id
                }
              })
            }}
          />
          <Button
            disabled={buttonDisabled}
            title={m.buttons.cart}
            variant="outlined"
            classes={{
              root: bem("button"),
            }}
            onClick={async () => {
              mergeDialog('cartDialog', { open: false })
              await router.push({
                pathname: webshopCartOverviewPage?.uri as string,
                query: {
                  cartId: cart.id
                }
              })
            }}
          />
        </div>
      </div>
    </Drawer>
  )
}
