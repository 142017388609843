import { State, useHookstate, SetPartialStateAction } from "@hookstate/core"

import { AdvancedFormFieldProps } from "src/components/common/advancedForm"
import { conditionalUpdate } from "src/helpers/form"

import { pushGtmStep } from "src/helpers/gtm"
import { xRMApiStep, xRMApiForm, xRMApiField } from "src/types/xRM"
import { v4 } from "uuid"

import { sortArrayByPosition } from "../helpers/form"

import { createAspState } from "./createAspState"

export interface Salesorder {
  salesorderId?: string
  activeStep: number
  steps: xRMApiStep[]
}

const defaultState = {
  salesorderId: v4(),
  activeStep: 0,
  steps: [],
} as Salesorder

const state = createAspState<Salesorder>(
  { ...defaultState, salesorderId: v4() },
  "salesorder",
)

/**
 * Merges a new form in the state.
 * @param form the data for the form
 */
export const mergeActiveSalesorderForm = (form: xRMApiForm) => {
  const activeStep = state.steps.nested(state.activeStep.get())
  activeStep.forms.merge({
    ...form,
  })
}

/**
 * Changes a step
 * @param stepId the id of the step
 * @param items the changed attributes of the step
 */
export const mergeSalesorderStepById = (items: xRMApiStep, stepId?: string) => {
  const steps = state.steps
  const stepIndex = (steps as State<xRMApiStep[]>)
    .get()
    .findIndex((e) => e.id === stepId)
  steps?.nested(stepIndex)?.merge(items)
  return state.steps.set((array) => sortArrayByPosition(array))
}

/**
 * Changes a form
 * @param stepId the index of the step
 * @param formId the index of the form
 * @param items the changed attributes of the field
 */
export const mergeSalesorderFormById = (
  items: xRMApiForm,
  stepId?: string,
  formId?: string,
) => {
  const steps = state.steps
  const stepIndex = (steps as State<xRMApiStep[]>)
    .get()
    .findIndex((e) => e.id === stepId)
  const forms = state.steps.nested(stepIndex).forms as State<xRMApiForm[]>
  const formIndex = forms?.get()?.findIndex((e) => e.id === formId)

  if (formIndex === -1) {
    return (state.steps.nested(stepIndex).forms as State<xRMApiForm[]>)?.set(
      (e) => [...e, items],
    )
  }
  delete items?.fields
  ;(state.steps.nested(stepIndex).forms as State<xRMApiForm[]>)
    ?.nested(formIndex)
    ?.merge(items)
  return (state.steps.nested(stepIndex).forms as State<xRMApiForm[]>).set(
    (array) => sortArrayByPosition(array),
  )
}

/**
 * Changes a field
 * @param stepId the index of the step
 * @param formId the index of the form
 * @param fieldId the index of the field
 * @param items the changed attributes of the field
 */
export const mergeSalesorderFieldById = (
  items: xRMApiField,
  stepId?: string,
  formId?: string,
  fieldId?: string,
) => {
  const steps = state.steps
  const stepIndex = (steps as State<xRMApiStep[]>)
    .get()
    ?.findIndex((e) => e.id === stepId)
  const formIndex = (steps.nested(stepIndex).forms as State<xRMApiForm[]>)
    .get()
    ?.findIndex((e) => e.id === formId)
  const form = (steps.nested(stepIndex).forms as State<xRMApiForm[]>).nested(
    formIndex,
  ) as State<xRMApiForm>
  const fieldIndex = (form?.fields as State<xRMApiField[]>)
    ?.get()
    ?.findIndex((e) => e.id === fieldId)
  ;(form?.fields as State<xRMApiField[]>)
    ?.nested(fieldIndex)
    ?.set((e) => ({ ...e, ...items }))
  return form?.fields.set((array) =>
    sortArrayByPosition(array as xRMApiField[]),
  )
}

/**
 * Changes a existing field
 * @param index the index of the form
 * @param items the changed attributes of the field
 * @param fieldId the id of the field
 */
export const mergeSalesorderField = async (
  index: number,
  items: AdvancedFormFieldProps,
  fieldId: string | null,
) => {
  const activeStepIndex = state.activeStep.get()
  const activeStep = (state.steps as State<xRMApiStep[]>).nested(
    activeStepIndex,
  )
  const activeForm =
    (activeStep.forms as State<xRMApiForm[]>).nested(index) ?? null
  // Call only if field changes value
  if (
    items.value !== undefined &&
    (typeof items.value === "boolean" ||
      typeof items.value === "string" ||
      typeof items.value === "number" ||
      Array.isArray(items.value)) &&
    fieldId
  ) {
    let values = [items?.value]
    if (Array.isArray(values?.[0])) {
      values = values.flat() as any
    }
    for (let i = 0; i < values.length; i++) {
      await conditionalUpdate(
        state.steps,
        activeStep,
        activeForm,
        fieldId,
        values[i],
      )
    }
  }
  const fieldIndex = (activeForm.fields.get() as xRMApiField[]).findIndex(
    (e) => e.id === fieldId,
  )
  const field = (activeForm.fields as State<xRMApiField[]>).nested(fieldIndex)
  field.set((e) => ({
    ...e,
    ...(items as xRMApiField),
  }))
}

/**
 * Set the steps
 * @param steps the index of the form
 */
export const setSalesorderSteps = (steps: xRMApiStep[]) =>
  state.steps.set(steps)

/**
 * Merge the activeStep state
 * @param activeStep the index of the form
 */
export const setSalesorderActiveStep = (activeStep: number) => {
  state.merge({ activeStep })
  pushGtmStep(
    activeStep,
    state?.steps?.get()[activeStep]?.label,
    "mainExhibitorNewBooking",
  )
}

/**
 * Updates the state
 */
export const mergeSalesorder = (items: SetPartialStateAction<Salesorder>) =>
  state.merge(items)

/**
 * Resets the complete salesorder state
 */
export const resetSalesorder = () => {
  state.set({ ...defaultState, salesorderId: v4() })
}

/**
 * Returns complete Salesorder added to the state.
 * @returns the Salesorder
 */
export const getSalesorder = async () => state.get()

/**
 * React hook to receive complete Salesorder from the state.
 * @returns the Salesorder
 */
export const useSalesorder = () => {
  return useHookstate(state).get()
}
