import Box from "@mui/material/Box"
import Checkbox from "@mui/material/Checkbox"
import Chip from "@mui/material/Chip"
import FormControl from "@mui/material/FormControl"
import FormHelperText from "@mui/material/FormHelperText"
import InputLabel from "@mui/material/InputLabel"
import ListItemText from "@mui/material/ListItemText"
import MenuItem from "@mui/material/MenuItem"
import MatSelect, {
  SelectProps as MatSelectProps,
  SelectChangeEvent,
} from "@mui/material/Select"

import isArray from "lodash/isArray"
import React, { FC, MouseEventHandler } from "react"

import { create } from "src/helpers/bem"
import { TranslationMessages } from "src/translations"

import styles from "./Select.module.scss"

const bem = create(styles, "Select")

type SelectClassNamesProps = {
  root?: string
  icon?: string
  selectedItem?: string
  item?: string
}

export type SelectOption = {
  onclick?: MouseEventHandler<HTMLLIElement>
  key: string | number
  value: string
  disabled?: boolean
  description?: string
  helperText?: string
}

export type SelectProps = MatSelectProps & {
  errorText?: string
  helperText?: string
  messages?: TranslationMessages
  items?: SelectOption[]
  classes?: SelectClassNamesProps
  onValidate?: (error?: string) => void
}

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

export const Select: FC<SelectProps> = ({
  multiple,
  items = [],
  variant = "outlined",
  value,
  required,
  classes,
  errorText,
  messages,
  helperText,
  onChange,
  onValidate,
  ...props
}) => {
  const handleValidation = (e: SelectChangeEvent<unknown>) => {
    let value = e?.target?.value as string | string[]
    if (!onValidate) {
      return null
    }
    if (required && value?.length === 0) {
      return onValidate(
        messages?.components?.common?.fields?.required ??
          "This field is required!",
      )
    }
    return onValidate()
  }
  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth error={!!errorText} required={required}>
        <InputLabel disabled={props?.disabled}>{props?.label}</InputLabel>
        <MatSelect
          {...props}
          className={bem(undefined, undefined, classes?.selectedItem)}
          MenuProps={multiple ? MenuProps : undefined}
          multiple={multiple}
          required={required}
          variant={variant}
          classes={{
            select: classes?.root,
            icon: classes?.icon,
          }}
          renderValue={
            multiple && isArray(value)
              ? (values) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {(values as string[])?.map((value: string) => (
                      <Chip
                        key={value}
                        color="primary"
                        label={items?.find((e) => e.key === value)?.value}
                      />
                    ))}
                  </Box>
                )
              : (value) => {
                  return items?.find((e) => e.key === value)?.value ?? ""
                }
          }
          value={
            multiple
              ? value ?? []
              : items?.find((el) => el.key === value)?.key ?? ""
          }
          onChange={(e, children) => {
            handleValidation?.(e)
            onChange?.(e, children)
          }}
        >
          {items && items?.length > 0
            ? items.map((item) => {
                if (multiple) {
                  return (
                    <MenuItem
                      key={item.key}
                      className={bem(undefined, undefined, classes?.item)}
                      disabled={item.disabled}
                      value={item.key}
                    >
                      <Checkbox
                        checked={
                          Array.isArray(value)
                            ? value.includes(item.key)
                            : false
                        }
                      />
                      <ListItemText primary={item.value} />
                    </MenuItem>
                  )
                }
                return (
                  <MenuItem
                    key={item?.key}
                    className={bem(undefined, undefined, classes?.item)}
                    disabled={item.disabled}
                    value={item.key}
                    onClick={item.onclick}
                  >
                    <div>
                      <p>{item.value}</p>
                      {item.description && (
                        <span className={bem("item__description")}>
                          {item.description}
                        </span>
                      )}
                    </div>
                  </MenuItem>
                )
              })
            : null}
        </MatSelect>
        {errorText || helperText ? (
          <FormHelperText>{errorText ?? helperText}</FormHelperText>
        ) : null}
        {!errorText || helperText ? (
          <FormHelperText>
            {items?.find((e) => e.key === value)?.helperText ?? ""}
          </FormHelperText>
        ) : null}
      </FormControl>
    </Box>
  )
}
