import React, { FC } from "react"

import { BasicLayout } from "src/components/layouts/basic"
import { SharedPageProps } from "src/types/SharedPageProps"

export type ErrorPageProps = SharedPageProps

export const ServerError: FC<ErrorPageProps> = (props) => {
  return (
    <BasicLayout {...props}>
      <div>
        <h1>500</h1>
        <p>
          <strong>Oops, something went wrong.</strong>
          <br />
          <br />
          Try to refresh this page or feel free to contact us if the problem
          persists.
        </p>
      </div>
    </BasicLayout>
  )
}

export const BadRequestError: FC<ErrorPageProps> = (props) => {
  return (
    <BasicLayout {...props}>
      <div>
        <h1>400</h1>
        <p>
          <strong>That's an error.</strong>
          <br />
          <br />
          Your client has issued a malformed or illegal request.
        </p>
      </div>
    </BasicLayout>
  )
}

export const NotAuthorizedError: FC<ErrorPageProps> = (props) => {
  return (
    <BasicLayout {...props}>
      <div>
        <h1>401</h1>
        <p>
          <strong>Unauthorized.</strong>
          <br />
          <br />
          Sorry your request could not be processed.
        </p>
      </div>
    </BasicLayout>
  )
}

export const ForbiddenError: FC<ErrorPageProps> = (props) => {
  return (
    <BasicLayout {...props}>
      <div>
        <h1>403</h1>
        <p>
          <strong>Forbidden.</strong>
          <br />
          <br />
          You don't have permission to access this resource on the server.
        </p>
      </div>
    </BasicLayout>
  )
}

export const NotFoundError: FC<ErrorPageProps> = (props) => {
  return (
    <BasicLayout {...props}>
      <div>
        <h1>404</h1>
        <p>
          <strong>Page not found.</strong>
          <br />
          <br />
          The page you were looking for could not be found.
        </p>
      </div>
    </BasicLayout>
  )
}
