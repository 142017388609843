import LinearProgress from "@mui/material/LinearProgress"
import React, { FC, useState } from "react"

import {
  AdvancedForm,
  AdvancedFormStepProps,
} from "src/components/common/advancedForm"

import { formatFormData } from "src/helpers/form"
import { postForm } from "src/queriesXRM/form"
import {
  useBooking,
  setDetailActiveStep,
  mergeDetailFormField,
} from "src/states/bookingDetail"
import { resetSalesorder } from "src/states/salesorder"

import { TranslationMessages } from "src/translations"
import { xRMApiSalesorderPhase, xRMApiStep } from "src/types/xRM"

import { loadBooking } from "./BookingDetail"

export type BookingDetailFormProps = {
  messages: TranslationMessages
}

export const BookingDetailForm: FC<BookingDetailFormProps> = ({ messages }) => {
  const formTranslations = messages.pages.standBooking
  const [loading, setLoading] = useState(false)
  const { detail, booking } = useBooking()

  const onSendForm = async (phase?: xRMApiSalesorderPhase) => {
    setLoading(true)
    const data = await formatFormData(
      "Main",
      detail?.activeStep,
      detail?.steps as ReadonlyArray<xRMApiStep>,
      undefined,
      booking?.id,
      phase,
    )
    return postForm(data, phase)
      .then(async () => {
        resetSalesorder()
        if (phase !== "Draft") {
          await loadBooking()
        }
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  if (loading) {
    return <LinearProgress color="primary" />
  }
  return (
    <AdvancedForm
      stepLabelsClickable
      activeStep={detail?.activeStep}
      data={detail?.steps as AdvancedFormStepProps[]}
      formMessages={formTranslations}
      handleChangeActiveStep={setDetailActiveStep}
      handleMergeField={mergeDetailFormField}
      id={booking?.id !== null ? booking?.id : ""}
      messages={messages}
      buttons={{
        backButtonLabel: formTranslations.buttons.back,
        nextButtonLabel: formTranslations.buttons.next,
        draftButtonLabel: formTranslations.buttons.draft,
        finishButtonLabel: formTranslations.buttons.finish,
      }}
      onSendForm={onSendForm}
    />
  )
}
