import ArrowCircleDown from "@mui/icons-material/ArrowCircleDown"
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowUp from "@mui/icons-material/KeyboardArrowUp"

import Collapse from "@mui/material/Collapse"
import Icon from "@mui/material/Icon"
import IconButton from "@mui/material/IconButton"
import TableCell from "@mui/material/TableCell"
import MatTableRow from "@mui/material/TableRow"

import React, { FC, MouseEvent, useState } from "react"

import { Avatar } from "src/components/common/avatar"
import {
  DataTable,
  DataTableTranslations,
} from "src/components/common/dataTable"
import { Select } from "src/components/common/select"

import { create } from "src/helpers/bem"
import { isIsoDate, formatLocaleDate } from "src/helpers/date"
import { useLocale } from "src/states/locale"

import {
  TableData,
  TableActions,
  TableClassNames,
  TableTranslations,
} from "./Table"

import styles from "./TableRow.module.scss"

const bem = create(styles, "TableRow")

export type TableRowProps = {
  index: number
  print?: boolean
  row: TableData
  iconActions?: TableActions[]
  showAvatar?: boolean
  classNames?: TableClassNames
  dataTableTranslations?: DataTableTranslations
  cellTranslations: TableTranslations
  handleClickCell?: (e: MouseEvent<unknown>, name: string) => void
}

export const TableRow: FC<TableRowProps> = ({
  index,
  print,
  row,
  iconActions,
  showAvatar,
  classNames,
  dataTableTranslations,
  cellTranslations,
  handleClickCell,
}) => {
  const locale = useLocale()
  const [open, setOpen] = useState(false)
  const labelId = `${row?.name}-${index}`
  const inactiveData = row?.isActiveBooking !== undefined && !row?.isActiveBooking
  const tableRowModifier = {
    "has-link": !!handleClickCell,
  }
  return (
    <>
      <MatTableRow
        key={labelId}
        hover
        className={bem(undefined, tableRowModifier)}
        sx={row?.children ? { "& > *": { borderBottom: "unset" } } : {}}
        tabIndex={-1}
        onClick={(event) =>
          handleClickCell ? handleClickCell(event, row.name) : undefined
        }
      >
        {!print ? (
          <>
            {row?.children ? (
              <TableCell>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => setOpen(!open)}
                >
                  {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                </IconButton>
              </TableCell>
            ) : null}
          </>
        ) : null}
        {showAvatar ? (
          <TableCell align="left" id={labelId}>
            <Avatar
              size={40}
              username={row?.name && row?.name !== "-" ? row?.name : undefined}
            />
          </TableCell>
        ) : null}
        <TableCell
          align="left"
          className={bem("first__column", undefined, classNames?.column)}
          id={labelId}
          sx={ inactiveData? { color: "gray" } : {}}
        >
          {row?.name ?? "-"}
        </TableCell>
        {Object.keys(row)
          .filter((el) => el !== "name")
          .map((cellKey) => {
            if (!cellTranslations[cellKey]) {
              return null
            }
            const defaultValue = row?.[cellKey]
            let value = row?.[cellKey]
            if (Array.isArray(value) || value === undefined) {
              return (
                <TableCell
                  key={cellKey}
                  align="left"
                  sx={ inactiveData? { color: "gray" } : {}}
                  className={bem(
                    "table__cell",
                    `${cellKey}-${defaultValue?.toString()}`,
                    classNames?.column,
                  )}
                >
                  -
                </TableCell>
              )
            }
            if (
              isIsoDate(value?.toString()) &&
              typeof value !== "object" &&
              value !== null
            ) {
              value = formatLocaleDate(locale, value)
            } else if (
              cellTranslations[cellKey]?.items &&
              typeof value !== "object" &&
              value !== null
            ) {
              value =
                cellTranslations[cellKey]?.items?.[value as string | number]!
            }
            return (
              <TableCell
                key={cellKey}
                align="left"
                sx={ inactiveData? { color: "gray" } : {}}
                className={bem(
                  "table__cell",
                  `${cellKey}-${defaultValue?.toString()}`,
                  classNames?.column,
                )}
              >
                {typeof value === "object" && value !== null ? (
                  <Select {...value} />
                ) : (
                  value
                )}
              </TableCell>
            )
          })}
        {iconActions && iconActions?.length !== 0
          ? iconActions.map((icon, i) => (
              <TableCell key={`table-icon-action-${i}`} align="right">
                <IconButton
                  color="inherit"
                  size="small"
                  {...icon}
                  onClick={() => icon?.onClick?.(row)}
                >
                  {icon.icon}
                </IconButton>
                <Icon>{icon?.name}</Icon>
              </TableCell>
            ))
          : null}
        {handleClickCell ? (
          <TableCell align="right">
            <IconButton
              aria-label="arrow-circle-right"
              className={bem("icon")}
              color="primary"
              size="small"
            >
              <ArrowCircleDown fontSize="small" />
            </IconButton>
          </TableCell>
        ) : null}
      </MatTableRow>
      {open &&
      Array.isArray(row?.children) &&
      row?.children &&
      row?.children?.length > 0 ? (
        <MatTableRow>
          <TableCell></TableCell>
          <TableCell colSpan={Number(Object.keys(row)?.length)}>
            <Collapse unmountOnExit in={open} timeout="auto">
              <DataTable
                chunkSize={5}
                data={row?.children}
                translations={dataTableTranslations}
              />
            </Collapse>
          </TableCell>
        </MatTableRow>
      ) : null}
    </>
  )
}
