import {
  MobileDatePicker,
  MobileDatePickerProps,
} from "@mui/x-date-pickers/MobileDatePicker"
import React, { FC } from "react"

import { InputField, FieldVariant } from "src/components/common/inputfield"

export type DatePickerProps = Omit<
  MobileDatePickerProps<Date, Date>,
  "renderInput" | "onChange" | "name"
> & {
  name: string
  variant?: FieldVariant
  errorText?: string
  helperText?: string
  onValidate?: (value?: Date | null, name?: string) => void
  onChange: (
    date: Date | null,
    name?: string,
    keyboardInputValue?: string,
  ) => void
}

export const DatePicker: FC<DatePickerProps> = ({
  name,
  variant,
  errorText,
  helperText,
  onValidate,
  onChange,
  ...props
}) => (
  <MobileDatePicker
    {...props}
    views={["day"]}
    renderInput={(params) => (
      <InputField
        {...params}
        fullWidth
        errorText={errorText}
        helperText={helperText}
        name={name}
        type="text"
        variant={variant}
      />
    )}
    onChange={(value, keyboardInputValue) => {
      onValidate?.(value, name)
      onChange(value, name, keyboardInputValue)
    }}
  />
)
