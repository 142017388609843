import { LogLevel, PublicClientApplication } from "@azure/msal-browser"

const b2cPolicies = {
  names: {
    signUpSignIn: "B2C_1A_MB_SIGNUPORSIGNIN",
    resetPassword: "B2C_1A_MB_PASSWORDRESET",
  },
  authorities: {
    signUpSignIn: {
      authority: `https://${process.env.AAD_TENANT_NAME}.b2clogin.com/${process.env.AAD_TENANT_NAME}.onmicrosoft.com/${process.env.AAD_SIGN_UP_SIGN_IN_FLOW_NAME}`,
    },
    resetPassword: {
      authority: `https://${process.env.AAD_TENANT_NAME}.b2clogin.com/${process.env.AAD_TENANT_NAME}.onmicrosoft.com/${process.env.AAD_RESET_PASSWORD_FLOW_NAME}`,
    },
  },
  authorityDomain: `${process.env.AAD_TENANT_NAME}.b2clogin.com`,
}

export const msalConfig = {
  auth: {
    clientId: process.env.AAD_CLIENT_ID ?? "",
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
    redirectUri: "/login-success", // Points to window.location.origin. You must register this URI on Azure Portal/App Registration.
    postLogoutRedirectUri: "/", // Indicates the page to navigate after logout.
    navigateToLoginRequestUrl: true, // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: "localStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    iframeHashTimeout: 100000,
    loggerOptions: {
      loggerCallback: (level: any, message: any, containsPii: boolean) => {
        if (containsPii) {
          return
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message)
            return
          case LogLevel.Info:
            console.info(message)
            return
          case LogLevel.Verbose:
            console.debug(message)
            return
          case LogLevel.Warning:
            console.warn(message)
            return
        }
      },
    },
  },
}

export const loginRequest = {
  scopes: [process.env.AAD_SCOPE ?? ""],
}

export const resetPasswordRequest = {
  scopes: [process.env.AAD_SCOPE ?? ""],
  authority: b2cPolicies.authorities.resetPassword.authority,
}

export const msalInstance = new PublicClientApplication(msalConfig)
