import CircularProgress, {
  CircularProgressProps,
} from "@mui/material/CircularProgress"
import Stack from "@mui/material/Stack"
import React, { FC } from "react"

export type LoadingProps = CircularProgressProps

export const Loading: FC<LoadingProps> = (props) => (
  <Stack direction="row" spacing={2}>
    <CircularProgress {...props} />
  </Stack>
)
