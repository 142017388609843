import xrmApi from "src/services/xrmApi"

import { setCountries } from "src/states/countries"
import { getLocale } from "src/states/locale"
import { xRMApiCountryCode } from "src/types/xRM"

export const countries = async () => {
  xrmApi.get("/country").then(async (res) => {
    const locale = getLocale()
    const mappedCountries = await res?.data?.map((el: xRMApiCountryCode) => {
      let value
      // IMPORTANT: SET ADDITIONAL LANGUAGE IF XRM SUPPORTS A NEW LANGUAGE
      switch (locale) {
        case "de":
          value = el?.germanName
          break
        default:
          value = el?.englishName
      }
      return {
        ...el,
        key: el.id,
        value,
      }
    })
    return setCountries(mappedCountries)
  })
}
