import { FC } from "react"

import { create } from "src/helpers/bem"

import { useTranslation } from "src/hooks/translation"
import { xRMApiIncident, xRMApiIncidentType, xRMApiSalesorderDetail } from "src/types/xRM"

import styles from "./ShopOrderDetailsItemStatus.module.scss"

const bem = create(styles, "ShopOrderDetailsItemStatus")

const findIncidient = (incidents: xRMApiIncident[], type: xRMApiIncidentType, item: xRMApiSalesorderDetail) => incidents
	.find(i => i.incidentType === type && i.incidentPositions?.some(p => p.salesorderDetailId === item.id))

export const isCancelled = (item: xRMApiSalesorderDetail, incidents: xRMApiIncident[]) =>
	['Active', 'Closed'].includes(findIncidient(incidents, 'Cancellation', item)?.statecode ?? '')

export const hasComplaint = (item: xRMApiSalesorderDetail, incidents: xRMApiIncident[]) =>
	['Active', 'Closed'].includes(findIncidient(incidents, 'Claim', item)?.statecode ?? '')

export type ShopOrderDetailsItemStatusProps = {
	incidents: xRMApiIncident[]
	item: xRMApiSalesorderDetail
	size?: 'normal' | 'small'
}

export const ShopOrderDetailsItemStatus: FC<ShopOrderDetailsItemStatusProps> = ({ incidents, item, size }) => {
	const translation = useTranslation()
	const m = translation.messages.pages.shop.orders.details.status

	const complaintStatus = (item: xRMApiSalesorderDetail, incidents: xRMApiIncident[]) => {
		const incident = findIncidient(incidents, 'Claim', item)
		switch (incident?.statecode) {
			case 'Active': return m.complaint.requested
			case 'Closed': return m.complaint.cancelled
			case 'Cancelled': return m.complaint.denied
			default: return undefined
		}
	}

	const cancellationStatus = (item: xRMApiSalesorderDetail, incidents: xRMApiIncident[]) => {
		const incident = findIncidient(incidents, 'Cancellation', item)
		switch (incident?.statecode) {
			case 'Active': return m.cancellation.requested
			case 'Closed': return m.cancellation.cancelled
			case 'Cancelled': return m.cancellation.denied
			default: return undefined
		}
	}

	return (
		<div className={bem()}>
			{ isCancelled(item, incidents) &&
				<div
					className={`${bem('chip_cancellation')} ${size === 'small' && bem('small')}`}
					color="error"
				>
					{ cancellationStatus(item, incidents) }
				</div>
			}
			{ hasComplaint(item, incidents) &&
				<div
					className={`${bem('chip_complaint')} ${size === 'small' && bem('small')}`}
					color="error"
				>
					{ complaintStatus(item, incidents) }
				</div>
			}
		</div>
	)
}