import Head from "next/head"
import React, { CSSProperties, FC } from "react"

// ADD YOUR CUSTOM FONTS FOR THE PROJECT
import latoThinItalic from "src/assets/fonts/lato-100-italic.woff2"
import latoThin from "src/assets/fonts/lato-100.woff2"
import latoLightItalic from "src/assets/fonts/lato-300-italic.woff2"
import latoLight from "src/assets/fonts/lato-300.woff2"
import latoRegularItalic from "src/assets/fonts/lato-400-italic.woff2"
import latoRegular from "src/assets/fonts/lato-400.woff2"
import latoBoldItalic from "src/assets/fonts/lato-700-italic.woff2"
import latoBold from "src/assets/fonts/lato-700.woff2"
import latoExtraBoldItalic from "src/assets/fonts/lato-900-italic.woff2"
import latoExtraBold from "src/assets/fonts/lato-900.woff2"

type FontProps = {
  family: CSSProperties["fontFamily"]
  style?: CSSProperties["fontStyle"]
  weight: CSSProperties["fontWeight"]
  url: string
}

const Font: FC<FontProps> = ({ family, style = "normal", weight, url }) => (
  <Head>
    <link
      as="font"
      crossOrigin="anonymous"
      href={latoRegular}
      rel="preload"
      type="font/woff2"
    />
    <style
      dangerouslySetInnerHTML={{
        __html: `
          @font-face {
            font-family: '${family}';
            font-style: ${style};
            font-weight: ${weight};
            font-display: swap;
            src: url('${url}') format('woff2');
          }`
          .replace(/\s+/g, " ")
          .trim(),
      }}
    />
  </Head>
)

export const Fonts: FC = () => (
  <>
    <Font family="Lato" url={latoThin} weight={100} />
    <Font family="Lato" style="italic" url={latoThinItalic} weight={100} />
    <Font family="Lato" url={latoLight} weight={300} />
    <Font family="Lato" style="italic" url={latoLightItalic} weight={300} />
    <Font family="Lato" url={latoRegular} weight={400} />
    <Font family="Lato" style="italic" url={latoRegularItalic} weight={400} />
    <Font family="Lato" url={latoBold} weight={700} />
    <Font family="Lato" style="italic" url={latoBoldItalic} weight={700} />
    <Font family="Lato" url={latoExtraBold} weight={900} />
    <Font family="Lato" style="italic" url={latoExtraBoldItalic} weight={900} />
  </>
)
