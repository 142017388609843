import { State, SetPartialStateAction, useHookstate } from "@hookstate/core"

import {
  AdvancedForms,
  AdvancedFormFieldProps,
} from "src/components/common/advancedForm"
import { conditionalUpdate } from "src/helpers/form"
import { pushGtmStep } from "src/helpers/gtm"
import { xRMApiStep, xRMApiForm, xRMApiField } from "src/types/xRM"
import { v4 } from "uuid"

import { sortArrayByPosition } from "../helpers/form"

import { createAspState } from "./createAspState"

export interface CoExhibitorInvite {
  open: boolean
  salesorderId?: string
  activeStep: number
  byMail: AdvancedForms[]
  selfEntry: xRMApiStep[]
}

export const defaultState = {
  open: false,
  salesorderId: v4(),
  activeStep: 0,
  byMail: [
    {
      id: "emailInvite",
      fields: [
        // {
        //   position: 0,
        //   type: "languages",
        //   name: "language",
        //   required: true,
        // },
        // {
        //   position: 1,
        //   type: "email",
        //   name: "email",
        //   required: true,
        // },
      ],
    },
  ],
  selfEntry: [],
} as CoExhibitorInvite

const state = createAspState<CoExhibitorInvite>(
  { ...defaultState, salesorderId: v4() },
  "co-exhibitor-invite",
)

/**
 * Changes a step
 * @param stepId the id of the step
 * @param items the changed attributes of the step
 */
export const mergeCoExhibitorSelfInviteStepById = (
  items: xRMApiStep,
  stepId?: string,
) => {
  const steps = state.selfEntry
  const stepIndex = (steps as State<xRMApiStep[]>)
    .get()
    .findIndex((e) => e.id === stepId)
  steps?.nested(stepIndex)?.merge(items)
  return state.selfEntry.set((array) => sortArrayByPosition(array))
}

/**
 * Changes a form
 * @param stepId the index of the step
 * @param formId the index of the form
 * @param items the changed attributes of the field
 */
export const mergeCoExhibitorSelfInviteFormById = (
  items: xRMApiForm,
  stepId?: string,
  formId?: string,
) => {
  const steps = state.selfEntry
  const stepIndex = (steps as State<xRMApiStep[]>)
    .get()
    .findIndex((e) => e.id === stepId)
  const forms = state.selfEntry.nested(stepIndex).forms as State<xRMApiForm[]>
  const formIndex = forms?.get()?.findIndex((e) => e.id === formId)

  if (formIndex === -1) {
    return (
      state.selfEntry.nested(stepIndex).forms as State<xRMApiForm[]>
    )?.set((e) => [...e, items])
  }
  delete items?.fields
  ;(state.selfEntry.nested(stepIndex).forms as State<xRMApiForm[]>)
    ?.nested(formIndex)
    ?.merge(items)
  return (state.selfEntry.nested(stepIndex).forms as State<xRMApiForm[]>).set(
    (array) => sortArrayByPosition(array),
  )
}

/**
 * Changes a field
 * @param stepId the index of the step
 * @param formId the index of the form
 * @param fieldId the index of the field
 * @param items the changed attributes of the field
 */
export const mergeCoExhibitorSelfInviteFieldById = (
  items: xRMApiField,
  stepId?: string,
  formId?: string,
  fieldId?: string,
) => {
  const steps = state.selfEntry
  const stepIndex = (steps as State<xRMApiStep[]>)
    .get()
    ?.findIndex((e) => e.id === stepId)
  const formIndex = (steps.nested(stepIndex).forms as State<xRMApiForm[]>)
    .get()
    ?.findIndex((e) => e.id === formId)
  const form = (steps.nested(stepIndex).forms as State<xRMApiForm[]>).nested(
    formIndex,
  ) as State<xRMApiForm>
  const fieldIndex = (form?.fields as State<xRMApiField[]>)
    ?.get()
    ?.findIndex((e) => e.id === fieldId)
  ;(form?.fields as State<xRMApiField[]>)
    ?.nested(fieldIndex)
    ?.set((e) => ({ ...e, ...items }))
  return form?.fields.set((array) =>
    sortArrayByPosition(array as xRMApiField[]),
  )
}

/**
 * Changes a existing field
 * @param index the index of the form
 * @param items the changed attributes of the field
 * @param fieldId the id of the field
 */
export const mergeCoExhibitorSelfInviteField = async (
  index: number,
  items: AdvancedFormFieldProps,
  fieldId: string | null,
) => {
  const activeStepIndex = state.activeStep.get()
  const activeStep = (state.selfEntry as State<xRMApiStep[]>).nested(
    activeStepIndex,
  )
  const activeForm =
    (activeStep.forms as State<xRMApiForm[]>).nested(index) ?? null
  // Call only if field changes value
  if (
    items.value !== undefined &&
    (typeof items.value === "boolean" ||
      typeof items.value === "string" ||
      typeof items.value === "number" ||
      Array.isArray(items.value)) &&
    fieldId
  ) {
    let values = [items?.value]
    if (Array.isArray(values?.[0])) {
      values = values.flat() as any
    }
    for (let i = 0; i < values.length; i++) {
      await conditionalUpdate(
        state.selfEntry,
        activeStep,
        activeForm,
        fieldId,
        values[i],
      )
    }
  }
  const fieldIndex = (activeForm.fields.get() as xRMApiField[]).findIndex(
    (e) => e.id === fieldId,
  )
  const field = (activeForm.fields as State<xRMApiField[]>).nested(fieldIndex)
  field.set((e) => ({
    ...e,
    ...(items as xRMApiField),
  }))
}

/**
 * Set the steps
 * @param steps the index of the form
 */
export const setCoExhibitorSelfInviteSteps = (steps: xRMApiStep[]) =>
  state.selfEntry.set(steps)

/**
 * Changes a existing field in self entry
 * @param index the index of the form
 * @param items the changed attributes of the field
 * @param name the name of the field
 */
export const mergeCoExhibitorMailInviteField = (
  index: number,
  items: AdvancedFormFieldProps,
  name: string | null,
) => {
  let activeForm = state.byMail.nested(index)
  let fieldIndex = activeForm.fields.get().findIndex((e) => e.name === name)
  state.byMail
    .nested(index)
    .fields.nested(fieldIndex)
    .merge({
      ...items,
    })
}

/**
 * Updates the dialog open state
 * @param value the updated state
 */
export const mergeCoExhibitorInvite = (
  value: SetPartialStateAction<CoExhibitorInvite>,
) => state.merge(value)

/**
 * Set the active step index
 * @param activeStep the index of the form
 */
export const setCoExhibitorInviteActiveStep = (activeStep: number) => {
  state.merge({ activeStep })
  pushGtmStep(
    activeStep,
    state?.selfEntry?.get()[activeStep]?.label,
    "coExhibitorInvite",
  )
}

export const setCoExhibitorInvite = (value: CoExhibitorInvite) =>
  state.set(value)

/**
 * Resets the complete co-exhibitor invite state
 */
export const resetCoExhibitorInvite = () =>
  state.set({ ...defaultState, salesorderId: v4() })

/**
 * Resets the co-exhibitor self invite state
 */
export const resetCoExhibitorSelfInvite = () =>
  state.selfEntry.set(defaultState.selfEntry)

/**
 * Resets the co-exhibitor mail invite state
 */
export const resetCoExhibitorMailInvite = () =>
  state.byMail.set(defaultState.byMail)

/**
 * Returns complete CoExhibitorInvite added to the state.
 * @returns the CoExhibitorInvite
 */
export const getCoExhibitorInvite = async () => state.get()

/**
 * React hook to receive complete CoExhibitorInvite from the state.
 * @returns the CoExhibitorInvite
 */
export const useCoExhibitorInvite = () => {
  return useHookstate(state).get()
}
