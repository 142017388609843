import React, { FC } from "react"

import { Link } from "src/components/common/link"
import { create } from "src/helpers/bem"

import { setNavigation } from "src/states/navigation"

import { MenuLocationEnum_menuItems_nodes_childItems_nodes } from "src/types/wordpress/generated/MenuLocationEnum"

import styles from "./NavigationDropdownItem.module.scss"

const bem = create(styles, "NavigationDropdownItem")

export type NavigationDropdownItemProps = {
  item: MenuLocationEnum_menuItems_nodes_childItems_nodes
}

export const NavigationDropdownItem: FC<NavigationDropdownItemProps> = ({
  item,
}) => {
  if (!item?.url) {
    return null
  }
  return (
    <li key={item?.id} className={bem()}>
      <Link
        href={item?.connectedNode?.node?.uri ?? item?.url ?? "#"}
        target={item?.target ?? "_self"}
        variant="base"
        classNames={{
          label: bem("label"),
          link: bem("link"),
        }}
        onClick={() => setNavigation({ visible: false })}
      >
        {item?.label}
      </Link>
    </li>
  )
}
