import NoPhotographyIcon from "@mui/icons-material/NoPhotography"
import { Divider, ListItem, ListItemText } from "@mui/material"
import DOMPurify from "dompurify"
import groupBy from "lodash/groupBy"
import { FC } from "react"
import { ButtonProps } from "src/components/common/button"
import { Dialog } from "src/components/common/dialog"
import { LazyImage } from "src/components/common/lazyImage"
import { BasicLayoutStyleProps } from "src/components/layouts/basic"
import { create } from "src/helpers/bem"
import { formatPrice } from "src/helpers/price"
import { Dialogs, mergeDialog, useDialog } from "src/states/dialogs"
import { useLocale } from "src/states/locale"
import { useCategories } from "src/states/shop/category"
import { TranslationMessages } from "src/translations"

import styles from "./ShopOrderDetailsComplainDialog.module.scss"

const bem = create(styles, "ShopOrderDetailsComplainDialog")

export type ShopOrderDetailsComplainDialogProps = {
	messages: TranslationMessages
	style: BasicLayoutStyleProps
}

export const ShopOrderDetailsComplainDialog: FC<ShopOrderDetailsComplainDialogProps> = ({
	messages,
	style
}) => {
	const locale = useLocale()
	const dialog = useDialog('shopOrderDetailsComplainDialog') as Dialogs['shopOrderDetailsComplainDialog']
	const { categories } = useCategories()
	const m = messages.pages.shop.orders.details.complainDialog

	const contacts = Object
		.entries(
			groupBy(
				dialog.order?.salesorderDetails ?? [],
				detail => categories.find(c => c.id === detail.purchaseorderProduct?.parentCategoryId)?.contactString)
			)
		.map(([ contact, items ]) => ({ contact, items }))
		.sort((a, b) => a.contact === 'undefined' ? 1 : -1)

	let firstRowButtons: ButtonProps[] = [
		{
			title: m.buttons.close,
		},
	]

	return (
		<Dialog
			closeOnClick
			firstRowButtons={firstRowButtons}
			open={dialog.open}
			style={style}
			title={m.title}
			onClose={() => mergeDialog('shopOrderDetailsComplainDialog', { open: false })}
		>
			<div className={bem()}>
				<p className={bem('description')}>
					{m.description}
				</p>
				{contacts.map(({ contact, items }, i) =>
					<ListItem
						key={i}
						disablePadding
						className={bem('listitem')}
					>
						<ListItemText>
							<div className={bem('listitem__content')}>
								<div dangerouslySetInnerHTML={{
									__html: DOMPurify.sanitize(contact === 'undefined' ? m.noContact : contact),
								}} />
								<div>
									{items.map(item =>
										<div key={item.id} className={bem('listitem__content__container')}>
											<div className={bem('listitem__content__image')}>
												{item.purchaseorderProduct?.images?.[0].src
													? <LazyImage
														alt={item.purchaseorderProduct?.images?.[0].alt ?? item.purchaseorderProduct?.name ?? ""}
														src={item.purchaseorderProduct?.images?.[0].src} />
													: <NoPhotographyIcon color="disabled" />}
											</div>
											<div className={bem('listitem__content__detail')}>
												<div className={bem('listitem__content__detail__name')}>
													{item.name}
												</div>
												<div className={bem("listitem__content__detail__price")}>
													<div>
														{formatPrice(item.pricePerUnit, locale)} x {item.quantity} {locale === 'de' ? item.unit?.name : item.unit?.englishName}
													</div>
												</div>
											</div>
										</div>
									)}
								</div>
							</div>
						</ListItemText>
						{ i !== contacts.length - 1 && <Divider className={bem('divider')} /> }
					</ListItem>
				)}
			</div>
		</Dialog>
	)
}