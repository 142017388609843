import { none, useHookstate } from "@hookstate/core"

import { createAspState } from "./createAspState"

export type NotificationVariant = "info" | "success" | "warning" | "error"

export type Notification = {
  id?: number
  variant: NotificationVariant
  code?: string
  content?: string
  autoclose?: boolean
}

let notificationCount = 1

export const AUTOCLOSE_TIMEOUT = 5000

const state = createAspState<Notification[]>([], "notifications")

/**
 * Adds a new notification and returns the id of the created notification.
 * @param data the notification to be added
 * @returns the id of the created notification
 */
export const addNotification = (data: Notification): number => {
  const id = notificationCount++

  state.merge([{ id, ...data }])

  if ("autoclose" in data && data.autoclose) {
    setTimeout(() => removeNotification(id), AUTOCLOSE_TIMEOUT)
  }

  return id
}

/**
 * Removes a notification by id.
 * @param id the id of the notification
 */
export const removeNotification = (id?: number) => {
  if (!id) {
    return
  }

  const key = state.keys.find((key) => state[key]?.id?.get() === id)

  if (key === undefined) {
    return
  }

  state[key]?.set(none)
}

/**
 * Returns all notifications added to the state.
 * @returns the list of notifications
 */
export const getNotification = () => state.get()

/**
 * React hook to receive all notifications from the state.
 * @returns the list of notifications
 */
export const useNotifications = () => useHookstate(state).get()
