import React, { FC } from "react"

import { BaseLink, BaseLinkProps } from "src/components/common/base-link"
import { create } from "src/helpers/bem"

import styles from "./Link.module.scss"

const bem = create(styles, "Link")

export type LinkClassNames = {
  label?: string
  link?: string
}

export type LinkProps = BaseLinkProps & {
  variant?: "base" | "primary" | "secondary" | "white"
  large?: boolean
  href: string
  classNames?: LinkClassNames
  locale?: string
}

export const Link: FC<LinkProps> = React.forwardRef(
  ({
    variant = "base",
    large,
    href,
    children,
    classNames,
    locale,
    target,
    onMouseEnter,
    onClick,
  }, ref: React.Ref<HTMLAnchorElement>) => (
    <BaseLink
      ref={ref}
      href={href}
      locale={locale}
      target={target ?? undefined}
      className={bem(
        undefined,
        {
          [variant]: true,
          large: !!large,
        },
        classNames?.link,
      )}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
    >
      <span className={bem("label", undefined, classNames?.label)}>
        {children}
      </span>
    </BaseLink>
  ),
)

Link.displayName = "Link"
