import { SetPartialStateAction, useHookstate } from "@hookstate/core"

import { BookingCoExhibitor } from "src/states/bookingDetail"

import { createAspState } from "./createAspState"

export interface CoExhibitorDelete {
  open: boolean
  deleteDialogOpen: boolean
  activeUser?: BookingCoExhibitor | null
}

export const defaultState = {
  open: false,
} as CoExhibitorDelete

const state = createAspState<CoExhibitorDelete>(
  defaultState,
  "co-exhibitor-delete",
)

/**
 * Merges the state with new data
 * @param items the items of the state
 */
export const mergeCoExhibitorDelete = (
  items: SetPartialStateAction<CoExhibitorDelete>,
) => state.merge(items)

/**
 * Overwrites the state with new data
 * @param items the items of the state
 */
export const setCoExhibitorDelete = (items: CoExhibitorDelete) =>
  state.set(items)

/**
 * Resets the complete co-exhibitor invite state
 */
export const resetCoExhibitorDelete = () => state.set(defaultState)

/**
 * Returns complete CoExhibitorDelete added to the state.
 * @returns the CoExhibitorDelete
 */
export const getCoExhibitorDelete = () => state.get()

/**
 * React hook to receive complete CoExhibitorDelete from the state.
 * @returns the CoExhibitorDelete
 */
export const useCoExhibitorDelete = () => {
  return useHookstate(state).get()
}
