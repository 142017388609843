import TreeItem, { TreeItemProps } from "@mui/lab/TreeItem"

import React, { FC } from "react"

import { Switch } from "src/components/common/switch"

import { create } from "src/helpers/bem"

import styles from "./TreeViewItem.module.scss"

const bem = create(styles, "TreeViewItem")

export type TreeViewItemProps = Omit<TreeItemProps, "nodeId" | "onClick"> & {
  enableSwitch?: boolean
  id?: string
  nodeId?: string
  checked?: boolean
  childs?: TreeViewItemProps[]
  onClick?: (item: TreeViewItemProps) => void
}

export const TreeViewItem: FC<TreeViewItemProps> = ({
  enableSwitch,
  label,
  disabled,
  nodeId,
  childs,
  checked,
  onClick,
  ...props
}) => (
  <TreeItem
    {...props}
    className={bem()}
    disabled={disabled}
    nodeId={nodeId as string}
    classes={{
      label: !enableSwitch || childs ? bem("label__wrapper") : undefined,
    }}
    label={
      enableSwitch && !childs ? (
        <span className={bem("label")}>
          <span className={bem("label__content")}>{label}</span>
          <Switch checked={checked} disabled={disabled} />
        </span>
      ) : (
        label
      )
    }
    onClick={
      !childs && !disabled
        ? () => {
            onClick?.({ ...props, nodeId })
          }
        : undefined
    }
  >
    {childs && childs?.length > 0
      ? childs.map((el, i) => (
          <TreeViewItem
            {...el}
            key={el?.nodeId ?? `${nodeId}-${i}`}
            enableSwitch={enableSwitch}
            nodeId={el?.nodeId ?? `${nodeId}-${i}`}
            onClick={onClick}
          />
        ))
      : null}
  </TreeItem>
)
