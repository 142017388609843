import { createSimpleGlobalState } from "src/hooks/simpleGlobalState"
import { xRMApiSalesorderPhase, xRMApiSalesorderType } from "src/types/xRM"

export type Booking = {
  name: string
  [p: string]: number | string | xRMApiSalesorderPhase | xRMApiSalesorderType | any
}

export type Bookings = {
  loading: boolean
  bookings: Booking[]
}

const { getValue, setValue, resetValue, mergeValue, useValue } =
  createSimpleGlobalState<Bookings>(
    {
      loading: true,
      bookings: [],
    },
    "bookings",
  )

export {
  getValue as getBookings,
  setValue as setBookings,
  resetValue as resetBookings,
  mergeValue as mergeBookings,
  useValue as useBookings,
}