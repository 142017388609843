import MatChip from "@mui/material/Chip"
import { ChipProps as MatChipProps } from "@mui/material/Chip"

import React, { FC, memo } from "react"

import { create } from "src/helpers/bem"

import styles from "./Chips.module.scss"

const bem = create(styles, "Chips")

export type ChipItem = MatChipProps

export type ChipProps = {
  items: ChipItem[]
} & MatChipProps

export const Chips: FC<ChipProps> = memo(
  ({ items, color = "primary", onDelete, ...props }) => (
    <>
      {items && items?.length > 0 ? (
        <ul className={bem()}>
          {items.map((chip, i) => (
            <li key={chip?.key ?? `chip-${i}`} className={bem("chip__wrapper")}>
              <MatChip
                {...props}
                {...chip}
                className={bem("chip", chip?.className)}
                color={chip?.color ?? color}
                onDelete={(e) => chip?.onDelete?.(e) ?? onDelete?.(chip)}
              />
            </li>
          ))}
        </ul>
      ) : null}
    </>
  ),
)

Chips.displayName = "Chips"
