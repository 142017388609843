import { createSimpleGlobalState } from "src/hooks/simpleGlobalState"

export interface BookingExternal {
  id: string
  name: string
}

const { getValue, setValue, resetValue, useValue } = createSimpleGlobalState<
  BookingExternal[]
>([], "bookingsExternal")

export {
  getValue as getBookingsExternal,
  setValue as setBookingsExternal,
  resetValue as resetBookingsExternal,
  useValue as useBookingsExternal,
}
