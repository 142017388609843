export type AvatarStyle = {
  bgcolor: string
}

export type AvatarResult = {
  sx: AvatarStyle
  children: string
}

export function generateStringAvatar(name: string): AvatarResult {
  const firstLetter = name?.split(" ")?.[0]?.[0] ?? ""
  const secondLetter = name?.split(" ")?.[1]?.[0] ?? ""

  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${firstLetter}${secondLetter}`,
  }
}

function stringToColor(value: string) {
  let hash = 0
  let i

  /* eslint-disable no-bitwise */
  for (i = 0; i < value.length; i += 1) {
    hash = value.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = "#"

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    color += `00${value.toString(16)}`.slice(-2)
  }
  /* eslint-enable no-bitwise */

  return color
}
