import React, { FC, Fragment, CSSProperties } from "react"

import { Link } from "src/components/common/link"

import { create } from "src/helpers/bem"
import {
  GET_FOOTER,
  GET_FOOTER_footerMenu1_edges_node_menuItems_nodes,
} from "src/types/wordpress/generated/GET_FOOTER"

import styles from "./Footer.module.scss"

const bem = create(styles, "Footer")

export interface FooterStyleProps extends CSSProperties {
  "--footer-background": string
  "--footer-border-top-color": string
}

export const Footer: FC<GET_FOOTER> = ({
  themeOptions,
  footerMenu1,
  footerMenu2,
  footerMenu3,
}) => {
  const props = themeOptions?.footer
  if (!props) {
    return null
  }
  const { extended, border, logo, socialIcons, backgrounds, copyright } = props

  const modifiers = {
    "is-small": !extended,
  }

  const renderMenu = (
    title: string | null,
    nodes:
      | (GET_FOOTER_footerMenu1_edges_node_menuItems_nodes | null)[]
      | null
      | undefined,
  ) => {
    return (
      <div className={bem("column")}>
        {extended ? (
          <h4 className={bem("navigation__headline")}>{title}</h4>
        ) : null}
        {nodes && nodes?.length > 0 ? (
          <ul className={bem("navigation")}>
            {nodes?.map((el, i) => (
              <li key={`navigation-${i}`} className={bem("navigation__item")}>
                <Link
                  href={el?.connectedNode?.node?.uri ?? el?.url ?? "#"}
                  target={getTarget(el)}
                  classNames={{
                    label: bem("navigation__item__label"),
                    link: bem("navigation__item__link"),
                  }}
                >
                  {el?.label}
                </Link>
              </li>
            ))}
          </ul>
        ) : null}
      </div>
    )
  }

  const getTarget = (
    el: GET_FOOTER_footerMenu1_edges_node_menuItems_nodes | null,
  ) => {
    if (el?.connectedNode?.node?.uri) {
      return el?.target ?? "_self"
    }

    if (el?.url && !el?.url.includes(window.location.host)) {
      return "_blank"
    }

    return "_self"
  }

  const renderPartnerLogos = () => {
    if (logo?.partnerLogos && logo?.partnerLogos?.length > 0) {
      return (
        <div className={bem("partner__logos")}>
          {logo.partnerLogos.map((el, i) => (
            <Fragment key={el?.name}>
              {el?.url?.url ? (
                <a
                  href={el?.url?.url ?? "#"}
                  target={el?.url?.target ?? "_self"}
                >
                  <img
                    alt={el?.alt ?? ""}
                    className={bem("partner__logos__image")}
                    src={el?.src ?? ""}
                  />
                </a>
              ) : (
                <img
                  alt={el?.alt ?? ""}
                  className={bem("partner__logos__image")}
                  src={el?.src ?? ""}
                />
              )}
            </Fragment>
          ))}
        </div>
      )
    }
    return null
  }

  const renderSocialIcons = () => {
    if (socialIcons && socialIcons.length > 0) {
      return (
        <div className={bem("social__icons")}>
          {socialIcons.map((el, i) => (
            <a
              key={el?.name}
              className={bem("social__icons__link")}
              href={el?.url?.url ?? "#"}
              target={el?.url?.target ?? "_self"}
              style={{
                backgroundColor: el?.iconBg ?? "rgba(255, 255, 255, 0.75)",
              }}
            >
              <i
                className={bem(
                  "social__icons__icon",
                  undefined,
                  el?.icon ?? "",
                )}
                style={{
                  color: backgrounds?.background ?? "#4d4d4d",
                }}
              />
            </a>
          ))}
        </div>
      )
    }
    return null
  }

  return (
    <footer
      className={bem(undefined, modifiers)}
      style={
        {
          "--footer-background": backgrounds?.background,
          "--footer-border-top-color": border?.borderTopColor,
        } as FooterStyleProps
      }
    >
      <div className={bem("column__left")}>
        <div className={bem("column")}>
          <div className={bem("logo")}>
            {logo?.url?.url ? (
              <a
                href={logo.url?.url ?? ""}
                target={logo?.url?.target ?? "_self"}
              >
                <img
                  alt={logo?.alt ?? ""}
                  className={bem("logo__image")}
                  src={logo?.src ?? ""}
                />
              </a>
            ) : (
              <img
                alt={logo?.alt ?? ""}
                className={bem("logo__image")}
                src={logo?.src ?? ""}
              />
            )}
          </div>
        </div>
        {footerMenu1
          ? renderMenu(
              footerMenu1?.edges?.[0]?.node?.name ?? "",
              footerMenu1?.edges?.[0]?.node?.menuItems?.nodes,
            )
          : null}
        {footerMenu2 && extended
          ? renderMenu(
              footerMenu2?.edges?.[0]?.node?.name ?? "",
              footerMenu2?.edges?.[0]?.node?.menuItems?.nodes,
            )
          : null}
        {footerMenu3 && extended
          ? renderMenu(
              footerMenu3?.edges?.[0]?.node?.name ?? "",
              footerMenu3?.edges?.[0]?.node?.menuItems?.nodes,
            )
          : null}
      </div>
      <div className={bem("column-right")}>
        <div className={bem("column", { "is-last": true })}>
          <div className={bem("social__wrapper")}>
            {renderSocialIcons()}
            <span className={bem("copyright", modifiers)}>{copyright}</span>
          </div>
          {extended ? renderPartnerLogos() : null}
        </div>
        {extended ? (
          <span
            className={bem("overlay")}
            style={{
              backgroundColor:
                backgrounds?.backgroundOverlay ?? "rgba(255, 255, 255, 0.1)",
            }}
          ></span>
        ) : null}
      </div>
    </footer>
  )
}
