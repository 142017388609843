import { createSimpleGlobalState } from "src/hooks/simpleGlobalState"

import { xRMApiRegionCode } from "src/types/xRM"

type RenameByT<T, U> = {
  [K in keyof U as K extends keyof T
    ? T[K] extends string
      ? T[K]
      : never
    : K]: K extends keyof U ? U[K] : never
}

export type Region = RenameByT<{ id: "key"; name: "value" }, xRMApiRegionCode>

const { getValue, setValue, useValue } = createSimpleGlobalState<Region[]>(
  [],
  "regions",
)

export {
  getValue as getRegions,
  setValue as setRegions,
  useValue as useRegions,
}
