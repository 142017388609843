import MatButton from "@mui/material/Button"
import { ButtonProps as MatButtonProps } from "@mui/material/Button"

import React, { FC } from "react"

import { Loading } from "src/components/common/loading"

export type ButtonProps = MatButtonProps & {
  loading?: boolean
}

export const Button: FC<ButtonProps> = ({
  loading,
  variant = "outlined",
  size = "large",
  title,
  ...props
}) => (
  <MatButton {...props} variant={variant}>
    {!loading ? title : <Loading color="info" size={24} />}
  </MatButton>
)
