import MatDialog, { DialogProps as MatDialogProps } from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import Slide from "@mui/material/Slide"

import { TransitionProps } from "@mui/material/transitions"
import React, { FC, ReactNode } from "react"

import { Button, ButtonProps } from "src/components/common/button"
import { create } from "src/helpers/bem"

import styles from "./Dialog.module.scss"

const bem = create(styles, "Dialog")

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>,
) {
  return <Slide ref={ref} direction="up" {...props} />
})

export type DialogProps = {
  title?: string
  titleClassName?: string
  firstRowButtons?: ButtonProps[]
  secondRowButtons?: ButtonProps[]
  closeOnClick?: boolean
  children: ReactNode | string
} & MatDialogProps

export const Dialog: FC<DialogProps> = ({
  open,
  color = "primary",
  title,
  titleClassName,
  firstRowButtons,
  secondRowButtons,
  onClose,
  closeOnClick,
  children,
  ...props
}) => {

  const renderButtons = (buttons: ButtonProps[]) => {
    return <div className={bem("buttonRow")}>
        {buttons.map((button, i) => (
          <Button
            {...button}
            key={`dialog-button-${i}`}
            onClick={(e) => {
              button?.onClick?.(e)
              onClose?.(e, "backdropClick")
            }}
          >
            {button.title}
          </Button>
        ))}
      </div>
  }

  return (
    <MatDialog
      {...props}
      keepMounted
      open={open}
      TransitionComponent={Transition}
      onClose={
        closeOnClick
          ? (e, val) => {
              onClose?.(e, val)
            }
          : undefined
      }
    >
      {title ? (
        <DialogTitle className={titleClassName} color={color}>
          {title}
        </DialogTitle>
      ) : null}
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <div className={bem("buttons")}>
          {firstRowButtons && firstRowButtons?.length > 0 && renderButtons(firstRowButtons)}
          {secondRowButtons && secondRowButtons?.length > 0 && renderButtons(secondRowButtons)}
        </div>
      </DialogActions>
    </MatDialog>
  )
}
