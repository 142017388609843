import { State, SetPartialStateAction, useHookstate } from "@hookstate/core"

import { AdvancedFormFieldProps } from "src/components/common/advancedForm"
import { conditionalUpdate } from "src/helpers/form"

import { BookingCoExhibitor } from "src/states/bookingDetail"

import { xRMApiStep, xRMApiForm, xRMApiField } from "src/types/xRM"

import { createAspState } from "./createAspState"

export interface CoExhibitorEdit {
  open: boolean
  activeStep: number
  steps: xRMApiStep[]
  activeUser?: BookingCoExhibitor | null
}

export const defaultState = {
  open: false,
  activeStep: 0,
  steps: [],
} as CoExhibitorEdit

const state = createAspState<CoExhibitorEdit>(defaultState, "co-exhibitor-edit")

/**
 * Changes a existing field
 * @param index the index of the form
 * @param items the changed attributes of the field
 * @param fieldId the id of the field
 */
export const mergeCoExhibitorEditField = async (
  index: number,
  items: AdvancedFormFieldProps,
  fieldId: string | null,
) => {
  const activeStepIndex = state.activeStep.get()
  const activeStep = (state.steps as State<xRMApiStep[]>).nested(
    activeStepIndex,
  )
  const activeForm =
    (activeStep.forms as State<xRMApiForm[]>).nested(index) ?? null
  // Call only if field changes value
  if (
    items.value !== undefined &&
    (typeof items.value === "boolean" ||
      typeof items.value === "string" ||
      typeof items.value === "number" ||
      Array.isArray(items.value)) &&
    fieldId
  ) {
    let values = [items?.value]
    if (Array.isArray(values?.[0])) {
      values = values.flat() as any
    }
    for (let i = 0; i < values.length; i++) {
      await conditionalUpdate(
        state.steps,
        activeStep,
        activeForm,
        fieldId,
        values[i],
      )
    }
  }
  const fieldIndex = (activeForm.fields.get() as xRMApiField[]).findIndex(
    (e) => e.id === fieldId,
  )
  const field = (activeForm.fields as State<xRMApiField[]>).nested(fieldIndex)
  field.set((e) => ({
    ...e,
    ...(items as xRMApiField),
  }))
}

/**
 * Set the active step index
 * @param activeStep the index of the form
 */
export const setCoExhibitorEditActiveStep = (activeStep: number) =>
  state.activeStep.set(activeStep)

/**
 * Merges the state with new data
 * @param items the items of the state
 */
export const mergeCoExhibitorEdit = (
  items: SetPartialStateAction<CoExhibitorEdit>,
) => state.merge(items)

/**
 * Merges a new form in the state.
 * @param data the data for the booking
 */
export const mergeCoExhibitorEditSteps = (data: xRMApiStep[]) => {
  state.steps.merge({
    ...data,
  })
}

/**
 * Overwrites the state with new data
 * @param items the items of the state
 */
export const setCoExhibitorEdit = (items: CoExhibitorEdit) => state.set(items)

/**
 * Resets the complete co-exhibitor invite state
 */
export const resetCoExhibitorEdit = () => state.set(defaultState)

/**
 * Returns complete CoExhibitorEdit added to the state.
 * @returns the CoExhibitorEdit
 */
export const getCoExhibitorEdit = () => state.get()

/**
 * React hook to receive complete CoExhibitorEdit from the state.
 * @returns the CoExhibitorEdit
 */
export const useCoExhibitorEdit = () => {
  return useHookstate(state).get()
}
