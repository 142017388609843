import xrmApi from "src/services/xrmApi"

import { setNomenclature } from "src/states/nomenclature"

export const getNomenclature = async () => {
  xrmApi
    .get(`/nomenclature?exhibitionId=${process.env.NEXT_PUBLIC_XRM_EVENT_ID}`)
    .then((res) => {
      if (res?.data) {
        return setNomenclature(res?.data)
      }
      return null
    })
}
