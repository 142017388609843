import {
  ApolloClient,
  DefaultOptions,
  InMemoryCache,
  createHttpLink,
} from "@apollo/client"

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: "no-cache",
    errorPolicy: "ignore",
  },
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
}

const cache = new InMemoryCache({
  resultCaching: false,
})

/**
 * The credentials: 'include' allows cookies to be automatically sent
 * along with the request 'include' because backend is on another domain.
 *
 * @see https://www.apollographql.com/docs/react/networking/authentication/#cookie
 */

const link = createHttpLink({
  uri: `${process.env.NEXT_PUBLIC_WORDPRESS_ORIGIN}/${process.env.NEXT_PUBLIC_WORDPRESS_SITE_PATH}/graphql`,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "http://localhost:3000",
    "Access-Control-Allow-Credentials": "true",
  },
  credentials: "include",
})

export const client = new ApolloClient({
  connectToDevTools: true,
  link,
  cache,
  defaultOptions,
})

export default client
