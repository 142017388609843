import {
  SwipeableDrawer,
  SwipeableDrawerProps,
  useMediaQuery,
} from "@mui/material"
import { useTheme } from "@mui/material/styles"
import React, { FC } from "react"

export type AnchorProps = "left" | "top" | "right" | "bottom"

export type DrawerProps = SwipeableDrawerProps

export const Drawer: FC<DrawerProps> = ({ children, ...props }) => {
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"))
  return (
    <SwipeableDrawer
      PaperProps={
        smallScreen
          ? {
              sx: {
                width: 340,
              },
            }
          : {
              sx: {
                width: 500,
              },
            }
      }
      {...props}
    >
      {children}
    </SwipeableDrawer>
  )
}
