import xrmApi from "src/services/xrmApi"

import { getLocale } from "src/states/locale"
import { setRegions } from "src/states/regions"
import { xRMApiRegionCode } from "src/types/xRM"

export const regions = async () => {
  xrmApi.get("/region").then(async (res) => {
    const locale = getLocale()
    const mappedRegions = await res?.data?.map((el: xRMApiRegionCode) => {
      let value
      // IMPORTANT: SET ADDITIONAL LANGUAGE IF XRM SUPPORTS A NEW LANGUAGE
      switch (locale) {
        case "de":
          value = el?.name
          break
        default:
          value = el?.name
      }
      return {
        ...el,
        key: el.id,
        value,
      }
    })
    return setRegions(mappedRegions)
  })
}
