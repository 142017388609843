import { createSimpleGlobalState } from "src/hooks/simpleGlobalState"
import { MenuLocationEnum_menuItems_nodes_childItems } from "src/types/wordpress/generated/MenuLocationEnum"

export type NavigationData = {
  visible: boolean
  title?: string
  items?: MenuLocationEnum_menuItems_nodes_childItems | null
}

const { getValue, setValue, useValue, mergeValue } =
  createSimpleGlobalState<NavigationData>({ visible: false }, "navigation")

export {
  getValue as getNavigation,
  setValue as setNavigation,
  useValue as useNavigation,
  mergeValue as mergeNavigation,
}
