import NextImage, { ImageProps } from "next/image"
import React, { FC } from "react"

import { create } from "src/helpers/bem"

import styles from "./LazyImage.module.scss"

const bem = create(styles, "LazyImage")

export type LazyImageProps = ImageProps

export const LazyImage: FC<LazyImageProps> = ({ className, ...props }) => (
  <NextImage
    unoptimized
    className={bem(undefined, undefined, className)}
    fill={true}
    {...props}
  />
)
