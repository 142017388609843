import React, { forwardRef } from "react"

import { DataTable, DataTableItems } from "src/components/common/dataTable"
import { Table, TableData } from "src/components/common/table"

import { create } from "src/helpers/bem"
import { useBooking } from "src/states/bookingDetail"

import { TranslationMessages } from "src/translations"
import { HeaderProps } from "src/types/SharedPageProps"

import styles from "./BookingDetailPrint.module.scss"

const bem = create(styles, "BookingDetailPrint")

export type BookingDetailPrintProps = {
  header: HeaderProps
  title: string | null
  messages: TranslationMessages
}

export const printPageStyle = `
  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }
`

export const BookingDetailPrint = forwardRef<
  HTMLDivElement | null,
  BookingDetailPrintProps
>(({ header, title, messages }, ref) => {
  const logos = header.data.themeOptions?.header?.logos
  const { detail, coExhibitors } = useBooking()

  const renderHeader = (pageBreak?: boolean) => (
    <div
      className={bem("logos", undefined, pageBreak ? "pagebreak" : undefined)}
    >
      <img
        alt={logos?.main?.alt ?? ""}
        className={bem("logo")}
        src={logos?.main?.src ?? ""}
      />
      <img
        alt={logos?.secondary?.alt ?? ""}
        className={bem("logo")}
        src={logos?.secondary?.src ?? ""}
      />
    </div>
  )

  return (
    <div ref={ref} className={bem()}>
      {renderHeader()}
      {title && <h1 className={bem("headline__h1")}>{title}</h1>}
      <div className={bem("info")}>
        {detail?.steps?.length > 0
          ? detail?.steps?.map((step, a) => {
              const formatedData = step?.forms?.map((el) => ({
                ...el,
                items: el.fields,
              }))
              return (
                <div key={`form-${a}`} className={bem("info__table")}>
                  {a !== 0 ? renderHeader(true) : null}
                  <h5 className={bem("headline__h5")}>
                    {step?.label ??
                      (messages.pages.booking.detail.info as any)[
                        step?.id ?? ""
                      ]?.title ??
                      ""}
                  </h5>
                  <DataTable
                    data={formatedData as DataTableItems[]}
                    classNames={{
                      label: bem("info__table__label"),
                      value: bem("info__table__value"),
                    }}
                    translations={
                      (messages.pages.booking.detail.info as any)[
                        step?.id ?? ""
                      ]
                    }
                  />
                </div>
              )
            })
          : null}
      </div>
      <div className={bem("co__exhibitors")}>
        {coExhibitors && coExhibitors.length > 0 ? (
          <>
            <h3 className={bem("headline__h3")}>
              {messages.pages.booking.detail.coExhibitors.headline}
            </h3>
            <Table
              print
              data={coExhibitors as TableData[]}
              messages={messages}
              order="desc"
              orderBy="date"
              cellTranslations={
                messages.pages.booking.detail.coExhibitors.table
              }
              classNames={{
                column: bem("co__exhibitors__column"),
              }}
              dataTableTranslations={
                messages.pages.booking.detail.coExhibitors.dataTable
              }
            />
          </>
        ) : null}
      </div>
    </div>
  )
})

BookingDetailPrint.displayName = "BookingDetailPrint"
