import MatIconPerson from "@mui/icons-material/Person"
import MatAvatar from "@mui/material/Avatar"

import React, { FC, ReactNode } from "react"

import { create } from "src/helpers/bem"
import { generateStringAvatar } from "src/helpers/nameUtils"

import styles from "./Avatar.module.scss"

const bem = create(styles, "Avatar")

export type AvatarProps = {
  size?: number
  username?: string
  icon?: ReactNode
  photoUrl?: string
}

export const Avatar: FC<AvatarProps> = ({ size, username, icon, photoUrl }) => {
  const avatar = username
    ? generateStringAvatar(username)
    : {
        children: icon ?? <MatIconPerson className={bem("icon")} />,
      }
  return (
    <MatAvatar
      {...avatar}
      alt="UserImage"
      className={bem("container")}
      src={photoUrl}
      sx={{
        width: size ?? 50,
        height: size ?? 50,
      }}
    ></MatAvatar>
  )
}
