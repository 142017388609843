export const formatPrice = (
  price?: number | null,
  locale?: string | null,
  currency?: string | null,
) => {
  const formatter = new Intl.NumberFormat(locale ?? "de-DE", {
    style: "currency",
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    currency: currency ?? "EUR",
  })
  return price != null ? formatter.format(price) : "-"
}
