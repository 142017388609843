import Divider from "@mui/material/Divider"
import DOMPurify from "dompurify"
import React, { FC, Fragment } from "react"

import { create } from "src/helpers/bem"

import styles from "./DataTable.module.scss"

const bem = create(styles, "DataTable")

export type DataTableClassNames = {
  label?: string
  value?: string
}

export type TableTranslationItem = {
  [p: string]: string | undefined
}

export type DataTableTranslation = {
  title?: string
  items?: TableTranslationItem
}

export type DataTableTranslations = {
  [p: string]: DataTableTranslation
}

export interface DataTableItem {
  [p: string]: any
  type?: string | undefined
}

export type DataTableItems = {
  id: string
  label?: string
  items: DataTableItem[]
}

export type DataTableProps = {
  classNames?: DataTableClassNames
  chunkSize?: number
  stepId?: string
  translations?: DataTableTranslations
  data: DataTableItems[]
}

export const DataTable: FC<DataTableProps> = ({
  classNames,
  chunkSize = 4,
  translations,
  data,
}) => {
  function CustomLabel({
    useRichText = false,
    label,
  }: {
    useRichText?: boolean
    label?: string
  }) {
    const sanitizedLabel = DOMPurify.sanitize(label ?? "", {
      ADD_ATTR: ["target"],
    })

    return !useRichText ? (
      <span className={bem("item__label", undefined, classNames?.label)}>
        {label}
      </span>
    ) : (
      <span
        className={bem("item__label", undefined, classNames?.label)}
        dangerouslySetInnerHTML={{
          __html: sanitizedLabel,
        }}
      ></span>
    )
  }

  const renderValue = (
    key: string,
    label?: string,
    value?: string | string[],
    useRichText?: boolean,
  ) => (
    <div key={key} className={bem("item")}>
      <CustomLabel label={label} useRichText={useRichText} />
      <span className={bem("item__value", undefined, classNames?.value)}>
        {Array.isArray(value) ? value.join(", ") : value ?? "—"}
      </span>
    </div>
  )

  const renderValues = (id: string, items: DataTableItem[] | undefined) => {
    if (!items || items?.length === 0) {
      return null
    }
    let itemArray = [...items]
    const sortedArray = itemArray?.sort(
      (a, b) => (a?.position ?? 0) - (b?.position ?? 0),
    )
    const groups = sortedArray
      ?.map((e, i) => {
        return i % chunkSize === 0 ? sortedArray.slice(i, i + chunkSize) : null
      })
      .filter((e) => {
        return e
      })
    return groups.map((group, a) => (
      <Fragment key={`item-row-${a}`}>
        {a !== 0 ? <Divider /> : null}
        <div className={bem("item__row")}>
          {group?.map((item, b) => {
            const Label =
              item?.label ??
              (translations as any)?.[id]?.items?.[item.name ?? ""] ??
              null
            return renderValue(
              `item-row-${a}-${b}`,
              Label,
              typeof item?.value === "object"
                ? item?.value?.value
                : item?.value,
              item?.type === "checkboxContent",
            )
          })}
        </div>
      </Fragment>
    ))
  }

  return (
    <div className={bem()}>
      {data?.map((el: DataTableItems) => (
        <div key={el.id} className={bem("wrapper")}>
          {el?.label || el.id ? (
            <>
              {el?.label ||
              (translations as DataTableTranslations)?.[el.id ?? ""]?.title ? (
                <h6 className={bem("headline")}>
                  {el?.label ??
                    (translations as DataTableTranslations)?.[el.id ?? ""]
                      ?.title}
                </h6>
              ) : null}
            </>
          ) : null}
          {renderValues(el.id, el?.items)}
        </div>
      ))}
    </div>
  )
}
