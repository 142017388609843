import AttachFile from "@mui/icons-material/AttachFile"
import DownloadForOffline from "@mui/icons-material/DownloadForOffline"
import HighlightOff from "@mui/icons-material/HighlightOff"

import React, { FC, useEffect } from "react"

import { Card } from "src/components/common/card"

import { create } from "src/helpers/bem"

import { getFilesById, getBlobUri } from "src/queriesXRM/file"

import { useBooking } from "src/states/bookingDetail"
import {
  useBookingDetailFiles,
  setBookingDetailFiles,
  mergeBookingDetailFiles,
} from "src/states/bookingDetailFiles"

import { TranslationMessages } from "src/translations"

import styles from "./BookingDetailFileManagement.module.scss"

const bem = create(styles, "BookingDetailFileManagement")

export type BookingDetailFileManagementProps = {
  messages: TranslationMessages
}

export const BookingDetailFileManagement: FC<
  BookingDetailFileManagementProps
> = ({ messages }) => {
  const m = messages.pages.booking.detail.documents
  const { booking } = useBooking()
  const { files } = useBookingDetailFiles()

  const loadFiles = async () => {
    const files = await getFilesById("Attachment", booking?.id)
    setBookingDetailFiles(files)
  }

  useEffect(() => {
    loadFiles()
  }, [booking?.id])

  if (!files || files?.length === 0) {
    return null
  }
  return (
    <div className={bem()}>
      <Card>
        <div className={bem("title__wrapper")}>
          <div className={bem("title__icon")}>
            <DownloadForOffline
              className={bem("icon")}
              color="primary"
              fontSize="medium"
            />
          </div>
          <h5 className={bem("title")}>{m.title}</h5>
        </div>
        <div className={bem("content")}>
          {files.map((file) => (
            <div key={`file-${file?.fileId}`} className={bem("file__wrapper")}>
              <div
                className={bem("file")}
                onClick={() =>
                  getBlobUri(
                    file?.container as string,
                    file?.file as string,
                    booking?.id as string,
                    "Attachment",
                    file?.baseUrl as string,
                    "_blank",
                    "noopener,noreferrer",
                  )
                }
              >
                <div className={bem("file__icon__wrapper")}>
                  <AttachFile className={bem("file__icon")} fontSize="small" />
                </div>
                {file?.name && (
                  <span className={bem("file__title")}>{file?.name}</span>
                )}
                {file?.size && (
                  <span className={bem("file__size")}>({file?.size})</span>
                )}
              </div>
              {file?.deletable && (
                <button
                  className={bem("file__delete")}
                  onClick={() =>
                    mergeBookingDetailFiles({
                      open: true,
                      activeFileId: file?.fileId,
                    })
                  }
                >
                  <HighlightOff
                    className={bem("file__delete__icon")}
                    color="error"
                    fontSize="medium"
                  />
                </button>
              )}
            </div>
          ))}
        </div>
      </Card>
    </div>
  )
}
