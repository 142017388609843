import moment from "moment"

import xrmApi from "src/services/xrmApi"
import { Booking, mergeBookings } from "src/states/bookings"
import { Locale } from "src/translations"
import { xRMApiSalesorder } from "src/types/xRM"

export const loadBookings = async (locale: Locale) => {
  mergeBookings({ loading: true })
  mergeBookings({ loading: false, bookings: await requestBookings(locale) })
}

const requestBookings = async (locale: Locale) : Promise<Booking[]> =>
  getSalesorders()
    .then(salesorders =>
      salesorders.map(salesorder => ({
        id: salesorder.id!,
        name: salesorder.orderNumber!,
        exhibition: locale === "de"
          ? salesorder.exhibition!.name!
          : salesorder.exhibition!.englishName!,
        orderType: salesorder.orderType!,
        exhibitor: salesorder.clientAddressAccount1!,
        phase: salesorder.phase!,
        permissions: salesorder.permissions,
        createdOn: salesorder.createdOn ? moment(salesorder.createdOn).format("lll") : '',
        coexhibitorCount: salesorder.coexhibitorCount!,
        isActiveBooking: salesorder?.exhibition?.id === process.env.NEXT_PUBLIC_XRM_EVENT_ID,
        isSpecialOrder: Boolean(salesorder?.isSpecialOrder).valueOf(),
        publishInPortal : Boolean(salesorder?.publishInPortal).valueOf(),
        standnumber: salesorder.standnumber,
        standhall: salesorder.standhall,
      })))

export const getSalesorders = async () : Promise<xRMApiSalesorder[]> => {
  return xrmApi
    .get(`/salesorder?exhibitionId=${process.env.NEXT_PUBLIC_XRM_EVENT_ID}`)
    .then(res => res?.data ?? [])
}

export const getSalesorderById = async (id?: string | null) => {
  if (!id) {
    return null
  }
  return xrmApi.get(`/salesorder/${id}`).then(async (res) => {
    if (res?.data) {
      return res.data
    }
    return null
  })
}

export const getCoExhibitorSalesordersById = async (id?: string | null) => {
  if (!id) {
    return null
  }
  return xrmApi
    .get(`/salesorder/children?salesorderId=${id}`)
    .then(async (res) => {
      if (res?.data) {
        return res.data
      }
      return null
    })
}

export const deleteSalesorderById = async (id?: string | null) => {
  if (!id) {
    return null
  }
  return xrmApi.delete(`/salesorder/${id}`).then(async (res) => {
    if (res?.data) {
      return res.data
    }
    return null
  })
}
