import { SetPartialStateAction, useHookstate } from "@hookstate/core"

import {
  AdvancedForms,
  AdvancedFormFieldProps,
  AdvancedFormCondition,
} from "src/components/common/advancedForm"

import { AdvancedFormFieldDependency } from "src/components/common/advancedForm/AdvancedFormField"
import { createAspState } from "src/states/createAspState"


import { Product } from "./products"
import { ProductVariant } from "./productVariants"


export interface ProductAttributes {
  open: boolean
  product: Product | null,
  productVariant: ProductVariant | null,
  form: AdvancedForms[]
  dependencies: AdvancedFormFieldDependency[]
}

export const defaultState : ProductAttributes = {
  open: false,
  product: null,
  productVariant: null,
  form: [],
  dependencies: []
}

const state = createAspState<ProductAttributes>(
  { ...defaultState },
)

/**
 * Changes complete state
 * @param data account data
 */
export const setProductAttributesForm = (data: AdvancedForms[]) =>
  state.form.set(data)

/**
 * Changes a existing field
 * @param formIndex the index of the form
 * @param name the name of the field
 * @param items the changed attributes of the field
 */
export const mergeProductAttributesFormField = (formIndex: number, items: AdvancedFormFieldProps, fieldName: string | null) => {
  const fieldIndex = state
    .form
    .nested(formIndex)
    .fields
    .get()
    .findIndex(f => f.id === fieldName)

  const field = state
    .form
    .nested(formIndex)
    .fields
    .nested(fieldIndex)

  field.merge({ ...items })

  resolveFieldDependencies(formIndex, fieldIndex)
}

const resolveFieldDependencies = (formIndex: number, fieldIndex: number) => {
  const field = state
    .form
    .nested(formIndex)
    .fields
    .nested(fieldIndex)

  state.dependencies.get()
    .filter(d => d.key === field.get().id)
    .forEach(dependency => {
      dependency.targets?.forEach(target => {
        const targetFieldIndex = state
          .form
          .nested(formIndex)
          .fields
          .get()
          .findIndex(f => f.id === target.fieldId)

        const targetField = state
          .form
          .nested(formIndex)
          .fields
          .nested(targetFieldIndex)

        targetField.merge({
          conditions: dependency.value === field.get().value
            ? target.conditions as AdvancedFormCondition
            : targetField.get().defaultConditions as AdvancedFormCondition
        })

        resolveFieldDependencies(formIndex, targetFieldIndex)
      })
    })
}

/**
 * Updates the state
 * @param value the updated state
 */
export const mergeProductAttributes = (
  value: SetPartialStateAction<ProductAttributes>,
) => state.merge(value)

export const setProductAttributes = (value: ProductAttributes) => state.set(value)

/**
 * Resets the complete product attributes state
 */
export const resetProductAttributes = () =>
  state.set({ ...defaultState, open: false })

/**
 * Resets the product attributes form
 */
export const resetProductAttributesForm = () =>
  state.form.set([...defaultState.form])

/**
 * Returns complete ProductAttributes added to the state.
 * @returns the ProductAttributes
 */
export const getProductAttributes = async () => state.get()

/**
 * React hook to receive complete Attributes state.
 * @returns the ProductAttributes
 */
export const useProductAttributes = () => {
  return useHookstate(state).get()
}
