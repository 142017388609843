import { createSimpleGlobalState } from "src/hooks/simpleGlobalState"

import { xRMApiCountryCode } from "src/types/xRM"

type RenameByT<T, U> = {
  [K in keyof U as K extends keyof T
    ? T[K] extends string
      ? T[K]
      : never
    : K]: K extends keyof U ? U[K] : never
}

export type Country = RenameByT<{ id: "key"; name: "value" }, xRMApiCountryCode>

const { getValue, setValue, useValue } = createSimpleGlobalState<Country[]>(
  [],
  "countries",
)

export {
  getValue as getCountries,
  setValue as setCountries,
  useValue as useCountries,
}
