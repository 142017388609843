import Add from "@mui/icons-material/Add"
import React, { FC } from "react"

import { create } from "src/helpers/bem"

import {
  useBookingDetailPermissions,
  mergeBookingDetailPermissions,
} from "src/states/bookingDetailPermissions"

import { TranslationMessages } from "src/translations"

import styles from "./PermissionTableActions.module.scss"
import { PermissionTableActionsAddUserDialog } from "./PermissionTableActionsAddUserDialog"

const bem = create(styles, "PermissionTableActions")

export type PermissionTableActionsProps = {
  messages: TranslationMessages
}

export const PermissionTableActions: FC<PermissionTableActionsProps> = ({
  messages,
}) => {
  const { addUser } = useBookingDetailPermissions()
  const m = messages.pages.booking.detail.actions.permissions.actions
  return (
    <div className={bem()}>
      <button
        className={bem("action", "primary")}
        onClick={() =>
          mergeBookingDetailPermissions({
            addUser: {
              open: true,
              email: addUser.email,
              role: addUser.role,
            },
          })
        }
      >
        <Add fontSize="small" />
        <span className={bem("action__label")}>{m.add.title}</span>
      </button>
      <PermissionTableActionsAddUserDialog messages={messages} />
    </div>
  )
}
