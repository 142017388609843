import { ReactPlugin } from "@microsoft/applicationinsights-react-js"
import {
  ApplicationInsights,
  DistributedTracingModes,
} from "@microsoft/applicationinsights-web"

export const appInsightsPlugin = new ReactPlugin()

export const appInsights = new ApplicationInsights({
  config: {
    distributedTracingMode: DistributedTracingModes.W3C,
    connectionString: process.env.APP_INSIGHTS_CONNECTION,
    extensions: [appInsightsPlugin],
  },
})
