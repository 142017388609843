import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react"
import LocalMallOutlinedIcon from "@mui/icons-material/LocalMallOutlined"
import { SelectChangeEvent } from "@mui/material/Select"

import { useRouter } from "next/router"

import React, {
  FC,
  CSSProperties,
  useEffect,
  useState,
  ReactElement,
  useRef,
  useMemo,
} from "react"

import { loginRequest } from "src/auth/msalConfig"

import { Avatar } from "src/components/common/avatar"
import { Dropdown, DropdownRef } from "src/components/common/dropdown"
import { Icon } from "src/components/common/icon"
import { Link, LinkClassNames } from "src/components/common/link"
import { Select, SelectOption } from "src/components/common/select"

import { getTypoVariables } from "src/components/layouts/basic/BasicLayout.variables"

import { create } from "src/helpers/bem"
import { PageTemplates, getPage } from "src/helpers/pages"
import { resetStates } from "src/helpers/resetStates"
import { useAccount as useUser } from "src/states/account"
import { Booking, useBookings } from "src/states/bookings"
import { mergeDialog } from "src/states/dialogs"
import { setLocale, useLocale } from "src/states/locale"
import { setNavigation } from "src/states/navigation"
import { addNotification } from "src/states/notifications"
import {
  useCarts,
  mergeCarts,
  useCart,
  setCart,
  Carts,
  postRefreshCart,
} from "src/states/shop/carts"

import { LOCALES, Locale } from "src/translations"
import { SharedPageProps } from "src/types/SharedPageProps"

import { GET_HEADER } from "src/types/wordpress/generated/GET_HEADER"
import {
  GET_PAGE_page,
  GET_PAGE_page_featuredImage_node,
} from "src/types/wordpress/generated/GET_PAGE"

import styles from "./Header.module.scss"

const bem = create(styles, "Header")

export interface HeaderStyleProps extends CSSProperties {
  "--opacity": number
}

export type HeaderProps = {
  template?: string | null
  image?: GET_PAGE_page_featuredImage_node | null
} & GET_HEADER &
  Pick<GET_PAGE_page, "translations"> &
  Pick<SharedPageProps, "messages" | "typography" | "pages">

export const filterSelecableBookings = (booking: Booking): boolean => {
  return (booking.phase === "Service" && booking.isActiveBooking) || (booking.phase === "Placed" && booking.isSpecialOrder)
}

export const Header: FC<HeaderProps> = ({
  template,
  image,
  translations,
  messages,
  pages,
  typography,
  themeOptions,
}) => {
  const { company1 } = useUser()
  const router = useRouter()
  const locale = useLocale()
  const { instance, accounts } = useMsal()
  const account = useAccount(accounts[0])
  const isAuthenticated = useIsAuthenticated()
  const { bookings } = useBookings()
  const { currentCartId } = useCarts()
  const cart = useCart()
  const [photoUrl, setPhotoUrl] = useState("")
  const dropdownRef = useRef<DropdownRef>(null)
  const m = messages?.components?.core?.header
  const isEnabledWebshopMenuItems = process.env.ENABLE_WEBSHOP === "true"

  const bodyFont = getTypoVariables(
    "body",
    typography?.data.themeOptions?.typography?.body,
  )

  const onStorageUpdate = ({ key, oldValue, newValue }: StorageEvent) => {
    if (
      key !== "asp-shop-carts" ||
      !newValue ||
      !oldValue ||
      oldValue === newValue
    )
      return
    const [oldCart, newCart] = [oldValue, newValue].map(
      (v) =>
        (JSON.parse(v) as Carts).carts.find((c) => c.id === currentCartId)!,
    )
    const hasCartReset = oldCart.revisionNo === -1
    const hasCartChanged = oldCart.revisionNo >= newCart.revisionNo
    if (hasCartChanged && !hasCartReset) return
    setCart(newCart.products, !hasCartReset)
  }

  useEffect(() => {
    window.addEventListener("storage", onStorageUpdate)
    return () => {
      window.removeEventListener("storage", onStorageUpdate)
    }
  }, [])

  useEffect(() => {
    async function changeLocale() {
      const translation = translations?.find(
        (t) => t?.language?.slug === locale,
      )
      if (translation) {
        const query = router?.query
        delete query.locale
        delete query.slug
        await router.replace(
          {
            pathname: translation.uri!,
            query: router.query,
          },
          undefined,
          { shallow: false },
        )
      }
    }
    if (
      router?.pathname === "/[locale]/[...slug]" ||
      router?.pathname === "/"
    ) {
      changeLocale()
    }
  }, [locale, translations, router])

  const handleLogin = () => {
    instance
      .loginRedirect({
        ...loginRequest,
        extraQueryParameters: { lang: locale },
      })
      .catch((e) => {
        addNotification({
          variant: "error",
        })
      })
  }

  const items: SelectOption[] = LOCALES.map((locale) => {
    return {
      key: locale,
      value: m?.languageSelect?.[locale],
    }
  })

  const handleLanguageChange = (event: SelectChangeEvent<unknown>) => {
    if (event.target.value as Locale) {
      setLocale(event.target.value as Locale)
    }
  }

  const handleBookingChange = (event: SelectChangeEvent<unknown>) => {
    const id = event?.target?.value as string
    mergeCarts({
      currentCartId: id,
    })
  }

  const renderAvatar = (username?: string, size?: number) => {
    return <Avatar photoUrl={photoUrl} size={size} username={username} />
  }

  const renderLink = (
    template: PageTemplates,
    element: ReactElement,
    classNames?: LinkClassNames,
  ) => (
    <Link
      {...classNames}
      classNames={classNames}
      href={getPage(template, locale, pages)?.uri ?? "/"}
      variant="primary"
      onClick={() => dropdownRef?.current?.close()}
    >
      {element}
    </Link>
  )

  const handleLogout = async () => {
    await resetStates()
    instance
      .logoutRedirect({
        postLogoutRedirectUri: "/",
      })
      .catch((e) => {
        addNotification({
          variant: "error",
        })
      })
  }

  const formatedBookings = [
    {
      key: "0",
      value: `${m?.bookingSelect.default}`,
      onclick: () => {mergeDialog('salesorderDialog', { open: true })}
    },
    ...bookings
      .filter(filterSelecableBookings)
      .sort((a, b) => b.name.localeCompare(a.name))
      .map(booking => {
        const standhall = booking.standhall ? m.standSelection.standhall + booking.standhall : ""
        const standnumber = booking.standnumber ? m.standSelection.standnumber + booking.standnumber : ""
        return {
          key: booking.id,
          value: `${booking.name!} | ${booking.exhibitor}${standhall}${standnumber}`,
        }
      })
  ]

  const isShop =
    isAuthenticated &&
    ["Webshop Overview Template", "Webshop Product Detail Template"].includes(
      template ?? "",
    )

  const isShopAndHasBooking =
    isAuthenticated && isShop && currentCartId && currentCartId !== "0"

  const productSum = useMemo(
    () =>
      cart.products?.length ?? 0,
    [cart.products],
  )
  if (!m) {
    return null
  }
  return (
    <header
      className={bem()}
      style={
        image?.sourceUrl
          ? {
              backgroundImage: `url(${image?.sourceUrl})`,
              "--opacity": 0,
            }
          : ({
              "--opacity": 0,
              minHeight: "auto",
            } as HeaderStyleProps)
      }
    >
      <div className={bem("content")}>
        {/* The main logo (top left) */}
        <div className={bem("col", "left")}>
          <img
            alt={themeOptions?.header?.logos?.main?.alt ?? ""}
            className={bem("logo__main")}
            src={themeOptions?.header?.logos?.main?.src ?? ""}
            onClick={() => router.push("/")}
          />
          {themeOptions?.header?.logos?.secondary?.desc && (
            <p className={bem("logo__description")}>
              {themeOptions.header.logos.secondary.desc}
            </p>
          )}
          {themeOptions?.header?.logos?.secondary?.src ||
          themeOptions?.header?.logos?.secondary?.alt ? (
            <img
              alt={themeOptions?.header?.logos?.secondary?.alt ?? ""}
              className={bem("logo__secondary")}
              src={themeOptions?.header?.logos?.secondary?.src ?? ""}
            />
          ) : null}
        </div>
        <div className={bem("col", "right")}>
          <div className={bem("col__content", "left")}>
            {/* The toggle to switch between bookings for shopping cart */}
            {isShop && (
              <div className={bem("toggle")}>
                <Select
                  items={formatedBookings}
                  messages={messages}
                  name="booking-switch"
                  value={currentCartId}
                  variant="filled"
                  classes={{
                    root: bem("toggle__select"),
                    icon: bem("toggle__icon", "booking"),
                    selectedItem: bem("toggle__selectedItem"),
                    item: bem("toggle__item"),
                  }}
                  onChange={handleBookingChange}
                />
              </div>
            )}
            {/* The cart button of shop */}
            {isShopAndHasBooking && (
              <div
                className={bem("cart")}
                onClick={async () => {
                  mergeDialog("cartDialog", { open: true })
                  setCart(await postRefreshCart(cart))
                  mergeCarts({ loading: false })
                }}
              >
                <Avatar icon={<LocalMallOutlinedIcon />} />
                <span className={bem("cart__badge")}>
                  {productSum > 99 ? "99+" : productSum}
                </span>
              </div>
            )}
          </div>
          <div className={bem("col__content", "right")}>
            {/* The toggle to switch between languages */}
            <div className={bem("toggle")}>
              <Select
                items={items}
                messages={messages}
                name="language-switch"
                value={locale?.split("-")?.[0]}
                variant="filled"
                classes={{
                  root: bem("toggle__select"),
                  icon: bem("toggle__icon"),
                  selectedItem: bem("toggle__selectedItem"),
                  item: bem("toggle__item"),
                }}
                onChange={handleLanguageChange}
              />
            </div>
            <div className={bem("avatar__language__wrapper")}>
              {!isAuthenticated ? (
                <div
                  className={bem("avatar", {
                    "is-unauthorized": !isAuthenticated,
                  })}
                  onClick={handleLogin}
                >
                  {renderAvatar()}
                  <div
                    className={bem("avatar__content")}
                    style={{
                      ...bodyFont,
                    }}
                  >
                    {m.avatar.noAccount}
                  </div>
                </div>
              ) : (
                <Dropdown
                  ref={dropdownRef}
                  button={renderAvatar(
                    account?.name && account?.name !== "unknown"
                      ? account.name
                      : undefined,
                  )}
                >
                  <div
                    className={bem("avatar__dropdown")}
                    style={{
                      ...bodyFont,
                    }}
                  >
                    <div className={bem("avatar__header")}>
                      <span className={bem("avatar__header__company")}>
                        {company1 ?? null}
                      </span>
                      <span
                        className={bem("avatar__header__signout")}
                        onClick={handleLogout}
                      >
                        {m.avatar.signout}
                      </span>
                    </div>
                    <div className={bem("avatar__user")}>
                      <span
                        className={bem("avatar__image")}
                        onClick={() => dropdownRef?.current?.close()}
                      >
                        {renderLink(
                          "Account Overview Template",
                          renderAvatar(
                            account?.name && account?.name !== "unknown"
                              ? account.name
                              : undefined,
                            75,
                          ),
                          {
                            link: bem("avatar__link"),
                            label: bem("avatar__label"),
                          },
                        )}
                      </span>
                      <div className={bem("avatar__user__info")}>
                        <h3 className={bem("avatar__user__name")}>
                          {account?.name && account?.name !== "unknown"
                            ? account.name
                            : m.avatar.noName}
                        </h3>
                        <span className={bem("avatar__user__email")}>
                          {account?.idTokenClaims?.email as string}
                        </span>
                        {renderLink(
                          "Account Overview Template",
                          <>{m.avatar.viewAccount}</>,
                          {
                            link: bem("avatar__user__link"),
                            label: bem("avatar__user__label"),
                          },
                        )}
                        {isEnabledWebshopMenuItems &&
                          <div>
                          {renderLink(
                            "Webshop Orders Template",
                            <>{m.avatar.viewMyOrders}</>,
                            {
                              link: bem("avatar__user__link"),
                              label: bem("avatar__user__label"),
                            },
                          )}
                          <br />
                          {renderLink(
                            "Webshop Incidents Template",
                            <>{m.avatar.viewMyIncidents}</>,
                            {
                              link: bem("avatar__user__link"),
                              label: bem("avatar__user__label"),
                            },
                          )}
                          <br />
                          {renderLink(
                            "Webshop Standrequests Overview Template",
                            <>{m.avatar.viewMyStandbookingRequests}</>,
                            {
                              link: bem("avatar__user__link"),
                              label: bem("avatar__user__label"),
                            },
                          )}
                        </div>
                        }
                      </div>
                    </div>
                  </div>
                </Dropdown>
              )}
              <span
                className={bem("navigation__toggle")}
                data-cy="Navigation-Button"
                onClick={() => setNavigation({ visible: true })}
              >
                <Icon className={bem("navigation__toggle__icon")} name="bars" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}
