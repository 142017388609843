import { ApolloProvider } from "@apollo/client"
import { MsalProvider } from "@azure/msal-react"
import { AppInsightsErrorBoundary } from "@microsoft/applicationinsights-react-js"

import { Theme } from "@mui/material/styles"
import ThemeProvider from "@mui/system/ThemeProvider"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import React, { FC, ReactNode } from "react"

import client from "src/apollo/client"
import { msalInstance } from "src/auth/msalConfig"
import { BadRequestError } from "src/components/pages/ErrorPage"
import { TranslationProvider } from "src/hooks/translation"
import { appInsights, appInsightsPlugin } from "src/logging/loggingConfig"
import { SharedPageProps } from "src/types/SharedPageProps"

export type ProvidersProps = {
  messages: SharedPageProps["messages"]
  theme: Theme
  children?: ReactNode
}

appInsights.loadAppInsights()

export const Providers: FC<ProvidersProps> = ({
  children,
  messages,
  theme,
}) => (
  <AppInsightsErrorBoundary
    appInsights={appInsightsPlugin}
    onError={BadRequestError}
  >
    <MsalProvider instance={msalInstance}>
      <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
          <TranslationProvider messages={messages}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              {children}
            </LocalizationProvider>
          </TranslationProvider>
        </ThemeProvider>
      </ApolloProvider>
    </MsalProvider>
  </AppInsightsErrorBoundary>
)
