import React, { FC, memo } from "react"

import { SelectOption } from "../select"
import { Icon } from "src/components/common/icon"
import { Link } from "src/components/common/link"

import { create } from "src/helpers/bem"

import styles from "./Download.module.scss"

const bem = create(styles, "InfoBox")

export type DownloadProps = {
  items?: SelectOption[]
}

export const Download: FC<DownloadProps> = memo(({ items }) => (
  <div className={bem()}>
    <ul className={bem("items")}>
      {items &&
        items.map((item, i) => (
          <li key={`${i}-${item?.key}`} className={bem("item")}>
            <Icon className={bem("item__icon")} name="circleArrowRight" />
            <Link
              href={item?.value ?? ""}
              target="_blank"
              classNames={{
                link: bem("item__link"),
                label: bem("item__label"),
              }}
            >
              {item?.key}
            </Link>
          </li>
        ))}
    </ul>
  </div>
))

Download.displayName = "Download"
