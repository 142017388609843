import FormControl from "@mui/material/FormControl"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormLabel from "@mui/material/FormLabel"
import MatRadio from "@mui/material/Radio"
import RadioGroup, {
  RadioGroupProps as MatRadioGroupProps,
} from "@mui/material/RadioGroup"

import React, { FC, ChangeEvent } from "react"

import { TranslationMessages } from "src/translations"

export type RadioOption = {
  key: string | number
  value: string
  disabled?: boolean
}

export type RadioProps = MatRadioGroupProps & {
  required?: boolean
  disabled?: boolean
  label?: string
  errorText?: string
  messages?: TranslationMessages
  helperText?: string
  items?: RadioOption[]
  onValidate?: (error?: string) => void
}

export const Radio: FC<RadioProps> = ({
  value = "",
  label,
  required,
  disabled,
  items,
  name,
  messages,
  classes,
  errorText,
  helperText,
  onChange,
  onValidate,
  ...props
}) => {
  const handleValidation = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    const value = e?.target?.value
    if (!onValidate) {
      return null
    }
    if (required && !value) {
      return onValidate(
        messages?.components?.common?.fields?.required ??
          "This field is required!",
      )
    }
    return onValidate(undefined)
  }
  return (
    <FormControl>
      {label && (
        <FormLabel id={`${name}-label`} required={required}>
          {label}
        </FormLabel>
      )}
      <RadioGroup
        {...props}
        aria-labelledby={`${name}-label`}
        name={name}
        value={value}
        onChange={
          onChange &&
          ((e, value) => {
            handleValidation?.(e)
            onChange(e, value)
          })
        }
      >
        {items?.map((item) => (
          <FormControlLabel
            key={item.key}
            control={<MatRadio />}
            disabled={disabled ?? item.disabled ?? false}
            label={item.value}
            value={item.key}
          />
        ))}
      </RadioGroup>
    </FormControl>
  )
}
