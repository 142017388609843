import { hookstate } from "@hookstate/core"
import { localstored } from "@hookstate/localstored"

export function createAspState<T>(initialValue: T, localStorageKey?: string) {
  const isBrowser = typeof window !== "undefined"
  if (localStorageKey && isBrowser) {
    return hookstate<T>(
      initialValue,
      localstored({
        // key is optional,
        // if it is not defined, the extension requires and
        // uses the identifier from the @hookstate/identifiable
        key: `asp-${localStorageKey}`,
      }),
    )
  }
  return hookstate<T>(initialValue)
}
