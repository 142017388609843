import InfoIcon from "@mui/icons-material/InfoOutlined"
import DOMPurify from "isomorphic-dompurify"
import React, { FC } from "react"

import { Button } from "src/components/common/button"
import { Card } from "src/components/common/card"

import { create } from "src/helpers/bem"
import { mergeCoExhibitorInvite } from "src/states/coExhibitorInvite"

import { TranslationMessages } from "src/translations"

import styles from "./BookingDetailBanner.module.scss"

const bem = create(styles, "BookingDetailBanner")

export type BookingDetailBannerProps = {
  showContent1: boolean | undefined
  showContent2: boolean | undefined
  handleButtonClick2: () => void
  messages: TranslationMessages
}

export const BookingDetailBanner: FC<BookingDetailBannerProps> = ({
  messages,
  showContent1,
  showContent2,
  handleButtonClick2,
}) => {
  const m = messages.pages.booking.detail.banner
  const showContent = showContent1 || showContent2
  return showContent ? (
    <div className={bem()}>
      <Card>
        <div className={bem("title__wrapper")}>
          <div className={bem("title__icon")}>
            <InfoIcon className={bem("icon")} fontSize="small" />
          </div>
          <h5 className={bem("title")}>{m.title}</h5>
        </div>
        {showContent1 && (
          <div className={bem("content")}>
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(m.content1),
              }}
            />
            <Button
              className={bem("button")}
              title={m.button1}
              onClick={() =>
                mergeCoExhibitorInvite({
                  open: true,
                })
              }
            />
          </div>
        )}
        {showContent2 && (
          <div className={bem("content")}>
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(m.content2),
              }}
            />
            <Button
              className={bem("button")}
              title={m.button2}
              onClick={handleButtonClick2}
            />
          </div>
        )}
      </Card>
    </div>
  ) : null
}
