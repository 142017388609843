import React, { FC } from "react"

import { create } from "src/helpers/bem"

import { MenuLocationEnum_menuItems } from "src/types/wordpress/generated/MenuLocationEnum"

import styles from "./NavigationList.module.scss"
import { NavigationListItem } from "./NavigationListItem"

const bem = create(styles, "NavigationList")

export const NavigationList: FC<MenuLocationEnum_menuItems> = ({ nodes }) => {
  if (nodes?.length === 0) {
    return null
  }

  const items = nodes ?? []
  return (
    <ul className={bem()}>
      {items.map((item) => {
        if (!item) {
          return null
        }
        return <NavigationListItem key={item?.id} item={item} />
      })}
    </ul>
  )
}
