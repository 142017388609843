import DOMPurify from "isomorphic-dompurify"
import React, { FC, ReactNode, CSSProperties } from "react"

import { Card } from "src/components/common/card"
import { DocumentBox } from "src/components/common/documentBox"

import { Footer } from "src/components/core/footer"
import { Header } from "src/components/core/header"
import { Navigation } from "src/components/core/navigation"
import { Snackbar } from "src/components/core/snackbar"

import { AddCoExhibitorDialog } from "src/components/services/booking/detail/actions/addCoExhibitor"
import { DeleteCoExhibitorDialog } from "src/components/services/booking/detail/actions/deleteCoExhibitor"
import { DeleteFileDialog } from "src/components/services/booking/detail/actions/deleteFile"
import { EditCoExhibitorDialog } from "src/components/services/booking/detail/actions/editCoExhibitor"
import { PermissionDialog } from "src/components/services/booking/detail/actions/permissions"
import { RejectPlacementDialog } from "src/components/services/booking/detail/actions/placements"
import { ShopCartDialog } from "src/components/services/shop/cart/dialog"
import { ShopDetailProductAttributesDialog } from "src/components/services/shop/detail/ShopDetailProductAttributesDialog"
import { ShopOrderDetailsCancelOrderDialog } from "src/components/services/shop/orders/detail/ShopOrderDetailsCancelOrderDialog"
import { ShopOrderDetailsComplainDialog } from "src/components/services/shop/orders/detail/ShopOrderDetailsComplainDialog"
import { ShopOverviewStandRequestDialog } from "src/components/services/shop/overview/ShopOverviewStandRequestDialog"
import { ShopOverviewStandSelectionDialog } from "src/components/services/shop/overview/ShopOverviewStandSelectionDialog"

import { create } from "src/helpers/bem"
import { hexToRGB } from "src/helpers/hexToRGB"
import { SharedPageProps } from "src/types/SharedPageProps"

import styles from "./BasicLayout.module.scss"
import { getColorVariables, getTypoVariables } from "./BasicLayout.variables"

const bem = create(styles, "BasicLayout")

export interface BasicLayoutStyleProps extends CSSProperties {
  "--color-primary": string
  "--color-secondary": string
  "--color-border": string
}

export type StaticContactProps = {
  title?: string
  content?: string
}

export type DialogTypeProps =
  | "AddCoExhibitor"
  | "EditCoExhibitor"
  | "DeleteCoExhibitor"
  | "Permission"
  | "DeleteFile"
  | "RejectPlacement"
  | "ShopOverviewStandSelection"
  | "ShopOverviewStandRequest"
  | "ShopDetailProductFeature"
  | "ShopCart"
  | "ShopOrderDetailsComplain"
  | "ShopOrderDetailsCancelOrder"
export type BasicLayoutProps = {
  staticContact?: StaticContactProps
  loadDialogs?: DialogTypeProps[]
  bgColor?: "primary" | "secondary"
} & SharedPageProps

export const BasicLayout: FC<BasicLayoutProps> = ({
  page,
  pages,
  bgColor,
  navigation,
  header,
  footer,
  typography,
  color,
  children,
  messages,
  staticContact,
  loadDialogs = [],
}) => {
  const Page = page?.data
  const Template = Page?.template?.templateName
  const Documents = Page?.sidebar?.documents
  const Contact = Page?.sidebar?.contact
  const Color = color?.data?.themeOptions?.color
  const Typography = typography?.data?.themeOptions?.typography
  const showStaticContact = staticContact && staticContact?.content
  const showDocuments = Documents?.files && Documents?.files?.length > 0
  const showContact = Contact && Contact?.content && showDocuments
  const showSidebar = showDocuments || showContact || showStaticContact

  const style = {
    ...getColorVariables(Color),
    ...getTypoVariables("body", Typography?.body),
    ...getTypoVariables("h1", Typography?.heading?.h1),
    ...getTypoVariables("h2", Typography?.heading?.h2),
    ...getTypoVariables("h3", Typography?.heading?.h3),
    ...getTypoVariables("h4", Typography?.heading?.h4),
    ...getTypoVariables("h5", Typography?.heading?.h5),
    ...getTypoVariables("h6", Typography?.heading?.h6),
  } as BasicLayoutStyleProps
  let backgroundColor
  if (bgColor) {
    backgroundColor = hexToRGB(style?.[`--color-${bgColor}`], 0.05)
  }

  const loadDialog = (dialogName: DialogTypeProps, children: ReactNode) =>
    loadDialogs?.includes(dialogName) ? children : null
  return (
    <div
      className={bem()}
      style={{
        ...style,
        backgroundColor,
      }}
    >
      <Header
        image={Page?.featuredImage?.node}
        messages={messages}
        pages={pages}
        template={Template}
        translations={Page?.translations}
        typography={typography}
        {...header?.data}
      />
      <Navigation {...navigation} />
      <main className={bem("main", bgColor)} id="main">
        <div className={bem("col")}>{children}</div>
        {showSidebar ? (
          <div className={bem("col")}>
            {showStaticContact && (
              <Card>
                {staticContact!.title && (
                  <div className={bem("card__title")}>
                    {staticContact!.title}
                  </div>
                )}
                {staticContact!.content && (
                  <div className={bem("card__content")}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(staticContact!.content),
                      }}
                    />
                  </div>
                )}
              </Card>
            )}
            {showContact && (
              <Card>
                {Contact!.title && (
                  <div className={bem("card__title")}>{Contact!.title}</div>
                )}
                {Contact!.content! && (
                  <div className={bem("card__content")}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(Contact!.content!),
                      }}
                    />
                  </div>
                )}
              </Card>
            )}
            {showDocuments && (
              <Card>
                <DocumentBox
                  items={Documents!.files!}
                  title={Documents!.title}
                />
              </Card>
            )}
          </div>
        ) : null}
      </main>
      <Footer {...footer?.data} />
      {loadDialogs && (
        <>
          {loadDialog(
            "AddCoExhibitor",
            <AddCoExhibitorDialog messages={messages} style={style} />,
          )}
          {loadDialog(
            "EditCoExhibitor",
            <EditCoExhibitorDialog messages={messages} style={style} />,
          )}
          {loadDialog(
            "DeleteCoExhibitor",
            <DeleteCoExhibitorDialog messages={messages} style={style} />,
          )}
          {loadDialog(
            "Permission",
            <PermissionDialog messages={messages} style={style} />,
          )}
          {loadDialog(
            "DeleteFile",
            <DeleteFileDialog messages={messages} style={style} />,
          )}
          {loadDialog(
            "RejectPlacement",
            <RejectPlacementDialog messages={messages} style={style} />,
          )}
          {loadDialog(
            "ShopOverviewStandSelection",
            <ShopOverviewStandSelectionDialog
              messages={messages}
              pages={pages}
              style={style}
            />,
          )}
          {loadDialog(
            "ShopOverviewStandRequest",
            <ShopOverviewStandRequestDialog
              messages={messages}
              pages={pages}
              style={style}
            />,
          )}
          {loadDialog(
            "ShopDetailProductFeature",
            <ShopDetailProductAttributesDialog
              messages={messages}
              style={style}
            />,
          )}
          {loadDialog(
            "ShopCart",
            <ShopCartDialog messages={messages} pages={pages} style={style} />,
          )}
          {loadDialog(
            "ShopOrderDetailsComplain",
            <ShopOrderDetailsComplainDialog messages={messages} style={style} />
          )}
          {loadDialog(
            "ShopOrderDetailsCancelOrder",
            <ShopOrderDetailsCancelOrderDialog messages={messages} style={style} />
          )}
        </>
      )}
      <Snackbar messages={messages} />
    </div>
  )
}
