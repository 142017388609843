import { ImmutableObject } from "@hookstate/core"
import HighlightOffIcon from "@mui/icons-material/HighlightOff"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import NoPhotographyIcon from "@mui/icons-material/NoPhotography"
import { CardActions, CardContent, IconButton } from "@mui/material"

import { FC } from "react"

import { Button } from "src/components/common/button"
import { Card } from "src/components/common/card"
import { LazyImage } from "src/components/common/lazyImage"
import { create } from "src/helpers/bem"

import { formatPrice } from "src/helpers/price"
import { useTranslation } from "src/hooks/translation"
import { useLocale } from "src/states/locale"
import {
  CartProduct,
  getCartProductAmount,
  mergeCart,
} from "src/states/shop/carts"

import styles from "./ShopCartProductCard.module.scss"

const bem = create(styles, "ShopCartProductCard")

export type ShopCartProductCardProps = {
  item: ImmutableObject<CartProduct>
}

export const ShopCartProductCard: FC<ShopCartProductCardProps> = ({ item }) => {
  const locale = useLocale()
  const translation = useTranslation()
  const m = translation.messages.pages.shop.cart.productCard
  const product = item.productVariant!
  const productImage = product?.images?.[0]
  return (
    <Card>
      <CardContent>
        <div className={bem()}>
          <div className={bem("amount")}>
            <div className={bem("counter")}>
              <div className={bem("counter__widget")}>
                <IconButton
                  color="primary"
                  disabled={
                    getCartProductAmount(item) >=
                    (item?.productVariant?.maximumOrderAmount ?? 1000000)
                  }
                  onClick={() => mergeCart(item, (amount) => amount + 1)}
                >
                  <KeyboardArrowUpIcon />
                </IconButton>
                <div className={bem("counter__sum")}>
                  {getCartProductAmount(item)}
                </div>
                <IconButton
                  color="primary"
                  disabled={getCartProductAmount(item) <= 1}
                  onClick={() => mergeCart(item, (amount) => amount - 1)}
                >
                  <KeyboardArrowDownIcon />
                </IconButton>
              </div>
              <div className={bem("counter__unit")}>{product.unit}</div>
            </div>
          </div>
          <div className={bem("image")}>
            {productImage?.src ? (
              <LazyImage
                alt={productImage.alt ?? product.name ?? ""}
                src={productImage.src}
              />
            ) : (
              <NoPhotographyIcon color="disabled" />
            )}
          </div>
          <div className={bem("info")}>
            <div className={bem("info__name")}>{product.name}</div>
            <div className={bem("sum")}>
              à {formatPrice(product.price, locale)}
            </div>
            <div className={bem("total")}>
              {formatPrice(product.price! * getCartProductAmount(item), locale)}
            </div>
          </div>
        </div>
      </CardContent>
      <CardActions className={bem("actions")}>
        <div>
          <Button
            startIcon={<HighlightOffIcon />}
            title={m.remove}
            variant="text"
            onClick={() => mergeCart(item, (_) => 0)}
          />
        </div>
      </CardActions>
    </Card>
  )
}
