import React, { FC, ReactNode } from "react"

import { create } from "src/helpers/bem"

import { AdvancedFormCondition } from "./AdvancedForm"

import styles from "./AdvancedFormFieldContainer.module.scss"

const bem = create(styles, "AdvancedFormFieldContainer")

export type AdvancedFormFieldContainerProps = {
  field?: string
  description?: string
  conditions?: AdvancedFormCondition
  children: ReactNode
}

export const AdvancedFormFieldContainer: FC<
  AdvancedFormFieldContainerProps
> = ({ field, conditions, description, children }) => {
  const display = conditions?.display === false ? "none" : "block"
  return (
    <div className={bem(undefined, field)} style={{ display }}>
      {description && <p className={bem("description")}>{description}</p>}
      {children}
    </div>
  )
}
