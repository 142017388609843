import { useHookstate, SetPartialStateAction, none } from "@hookstate/core"

import { xRMApiFile } from "src/types/xRM"

import { createAspState } from "./createAspState"

export interface BookingDetailFiles {
  open: boolean
  files: xRMApiFile[]
  activeFileId?: string
}

export const defaultState = {
  open: false,
  files: [],
} as BookingDetailFiles

const state = createAspState<BookingDetailFiles>(
  defaultState,
  "booking-detail-files",
)

/**
 * Set the files state
 * @param data the data for the state
 */
export const setBookingDetailFiles = (data: xRMApiFile[]) =>
  state.files.set(data)

/**
 * Updates the state
 * @param data the data for the state
 */
export const mergeBookingDetailFiles = (
  data: SetPartialStateAction<BookingDetailFiles>,
) => state.merge(data)

/**
 * Remove an existing permission
 * @param fileId the id of the file
 */
export const removeBookingDetailFile = (fileId: string) => {
  const fileState = state.files.get()
  if (fileState?.length === 0) {
    return null
  }
  let fileIndex = fileState?.findIndex((e) => e?.fileId === fileId)
  state.files.nested(fileIndex).set(none)
}

/**
 * Resets the complete state
 */
export const resetBookingDetailFiles = () => state.set(defaultState)

/**
 * Returns the complete state.
 * @returns the BookingDetailFiles
 */
export const getBookingDetailFiles = () => state.get()

/**
 * React hook to receive complete BookingDetailFiles from the state.
 * @returns the BookingDetailFiles
 */
export const useBookingDetailFiles = () => {
  return useHookstate(state).get()
}
