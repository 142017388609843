import { SideNavItemProps } from "src/components/common/sideNav"
import { createSimpleGlobalState } from "src/hooks/simpleGlobalState"
import { xRMApiCategory } from "src/types/xRM"

export type Category = SideNavItemProps & xRMApiCategory

export type Categories = {
  loading: boolean
  categories: Category[]
}

const { getValue, setValue, mergeValue, useValueProxy } =
  createSimpleGlobalState<Categories>(
    {
      loading: true,
      categories: [],
    },
    "shop-categories",
  )

export {
  getValue as getCategories,
  setValue as setCategories,
  mergeValue as mergeCategories,
  useValueProxy as useCategories,
}
