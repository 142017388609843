import { useHookstate } from "@hookstate/core"
import {
  AdvancedForms,
  AdvancedFormFieldProps,
  AdvancedFormFieldDependency,
  AdvancedFormCondition,
} from "src/components/common/advancedForm"

import { xRMApiDependency, xRMApiIncident, xRMApiIncidentStateCode } from "src/types/xRM"

import { createAspState } from "./createAspState"

export type AccountFormWebshop = {
  isIncidentLoaded?: boolean
  incidentId?: string
  statecode?: xRMApiIncidentStateCode
  dependencies: AdvancedFormFieldDependency[]
  forms: AdvancedForms[]
}

export const defaultState =
  {
    isIncidentLoaded : false,
    dependencies:
    [
      {
        key: "companyCountryCodeId",
        targets: [
          {
            conditions: {
              display: true,
            },
            fieldId: "companyCountryRegionCodeId",
            formId: "deliveryAddress",
          },
        ],
      },
      {
        key: "companyClientLocation",
        targets: [
          {
            conditions: {
              display: true,
            },
            fieldId: "clientVatId",
            formId: "clientLocation",
          },
        ],
        value: 761330001 as unknown,
      },
      {
        key: "companyClientLocation",
        targets: [
          {
            conditions: {
              display: true,
            },
            fieldId: "businessCertificate",
            formId: "clientLocation",
          },
        ],
        value: 761330002 as unknown,
      }
    ],
    forms:
    [
      {
        id: "deliveryAddress",
        fields:
        [
          {
            position: 0,
            type: "languages",
            name: "language",
            id: "language",
            required: true,
          },
          {
            position: 1,
            type: "text",
            name: "companyName",
            id: "companyName",
            required: true,
            max: 100,
          },
          {
            position: 2,
            type: "text",
            name: "companyStreet",
            id: "companyStreet",
            required: true,
            max: 250,
          },
          {
            position: 3,
            type: "zip",
            name: "companyZipCode",
            id: "companyZipCode",
            required: true,
            max: 20,
          },
          {
            position: 4,
            type: "text",
            name: "companyCity",
            id: "companyCity",
            required: true,
            max: 100,
          },
          {
            position: 5,
            type: "country",
            name: "companyCountryCodeId",
            id: "companyCountryCodeId",
            required: true,
          },
          {
            position: 6,
            type: "region",
            name: "companyCountryRegionCodeId",
            id: "companyCountryRegionCodeId",
            conditions: {
              display: false,
            },
            defaultConditions: {
              display: false,
            },
            required: true,
          },
          {
            position: 7,
            type: "tel",
            name: "companyPhone",
            id: "companyPhone",
            required: true,
            max: 100,
          },
          {
            position: 8,
            type: "email",
            name: "companyEmail",
            id: "companyEmail",
            required: true,
            max: 100,
          },
        ],
      },
      {
        id: "clientLocation",
        fields: [
          {
            position: 0,
            type: "ustDeclarations",
            name: "companyClientLocation",
            id: "companyClientLocation",
            required: true,
          },
          {
            conditions: {
              display: false,
            },
            defaultConditions: {
              display: false,
            },
            id: "clientVatId",
            name : "clientVatId",
            max: 100,
            position: 1,
            type: "text",
            required: true,
          },
          {
            conditions: {
              display: false,
            },
            defaultConditions: {
              display: false,
            },
            position: 2,
            type: "fileAuthorization",
            id: "businessCertificate",
            name: "businessCertificate",
            items: [],
            required: false,
            multiple: false,
          }
        ],
      },
    ],
  } as AccountFormWebshop

const state = createAspState<AccountFormWebshop>(defaultState, "account-form-webshop")

/**
 * Changes complete state
 * @param data account data
 */
export const setAccountFormWebshop = (data: AccountFormWebshop) => state.set(data)

/**
 * Changes a existing field
 * @param index the index of the form
 * @param name the name of the field
 * @param items the changed attributes of the field
 */
export const mergeAccountFormWebshop = (
  index: number,
  items: AdvancedFormFieldProps,
  name: string | null,
) => {
  const fieldIndex = state.forms
    .nested(index)
    .fields.get()
    ?.findIndex((e) => e.id === name)
  state.forms
    .nested(index)
    .fields.nested(fieldIndex)
    ?.merge({
      ...items,
    })

    resolveFieldDependencies(index, fieldIndex)
}

/**
 * Changes a dependency field for country field
 * @param items the changed attributes of the field
 */
export const mergeDependencyForCountry = (
  items: xRMApiDependency,
) => {
  state.dependencies
    .nested(0)
    ?.merge({
      ...items,
    })

    const fieldIndex = state.forms
    .nested(0)
    .fields.get()
    ?.findIndex((e) => e.id === "companyCountryCodeId")
    resolveFieldDependencies(0, fieldIndex)

}

const resolveFieldDependencies = (formIndex: number, fieldIndex: number) => {
  const field = state
    .forms
    .nested(formIndex)
    .fields
    .nested(fieldIndex)

  state.dependencies.get()
    .filter(d => d.key === field.get().id)
    .forEach(dependency => {
      dependency.targets?.forEach(target => {
        const targetFieldIndex = state
          .forms
          .nested(formIndex)
          .fields
          .get()
          .findIndex(f => f.id === target.fieldId)

        const targetField = state
          .forms
          .nested(formIndex)
          .fields
          .nested(targetFieldIndex)

        targetField.merge({
          conditions: dependency.value === field.get().value
            ? target.conditions as AdvancedFormCondition
            : targetField.get().defaultConditions as AdvancedFormCondition
        })

        resolveFieldDependencies(formIndex, targetFieldIndex)
      })
    })
}

/**
 * Resets the complete Account state
 */
export const resetAccountFormWebshop = () => state.set(defaultState)

/**
 * Returns complete Account added to the state.
 * @returns the Account
 */
export const getAccountFormWebshop = () => state.get()

/**
 * React hook to receive complete Account from the state.
 * @returns the Account
 */
export const useAccountFormWebshop = () => {
  return useHookstate(state).get()
}

/**
 * Sets data in the account form webshop state.
 * @param data the data for the account form webshop
 */
export const setIncident = (data: xRMApiIncident) => {

  if (!data.id) {
    state.set(defaultState)
    state.incidentId.set(crypto.randomUUID())
  }
  else
  {
    state.incidentId.set(data.id)
    state.isIncidentLoaded.set(true)
    state.statecode.set(data.statecode)
  }
}

