import FilledInput from "@mui/material/FilledInput"
import OutlinedInput from "@mui/material/OutlinedInput"
import React, { FC } from "react"

export type FieldVariant = "filled" | "standard" | "outlined"

export type InputFieldVariantProps = {
  variant?: FieldVariant
}

export const InputFieldVariant: FC<InputFieldVariantProps> = ({
  variant = "filled",
}) => {
  switch (variant) {
    case "filled":
      return <FilledInput />
    case "outlined":
      return <OutlinedInput />
    default:
      return null
  }
}
