import { useIsAuthenticated } from "@azure/msal-react"
import { Alert, CircularProgress } from "@mui/material"
import { SelectChangeEvent } from "@mui/material/Select"
import { useRouter } from "next/router"
import React, { FC, useEffect, useMemo } from "react"

import { ButtonProps } from "src/components/common/button"
import { Dialog } from "src/components/common/dialog"
import { Select } from "src/components/common/select"
import { filterSelecableBookings } from "src/components/core/header"
import { BasicLayoutStyleProps } from "src/components/layouts/basic"

import { create } from "src/helpers/bem"

import { mergeAccount } from "src/states/account"
import { useAccountFormWebshop } from "src/states/accountFormWebshop"
import { mergeBookingDetailPermissions } from "src/states/bookingDetailPermissions"
import { useBookings } from "src/states/bookings"
import { Dialogs, mergeDialog, useDialog } from "src/states/dialogs"
import { useLocale } from "src/states/locale"
import { useCarts, mergeCarts } from "src/states/shop/carts"
import { useFilter, mergeFilter } from "src/states/shop/filter"

import { TranslationMessages } from "src/translations"

import { PagesProps } from "src/types/SharedPageProps"

import styles from "./ShopOverviewStandSelectionDialog.module.scss"

const bem = create(styles, "ShopOverviewStandSelectionDialog")

export type ShopOverviewStandSelectionDialogProps = {
  messages: TranslationMessages
  pages: PagesProps
  style: BasicLayoutStyleProps
}

export const ShopOverviewStandSelectionDialog: FC<
  ShopOverviewStandSelectionDialogProps
> = ({ messages, pages, style }) => {
  const router = useRouter()
  const locale = useLocale()
  const isAuthenticated = useIsAuthenticated()
  const authorizationRequested = useAccountFormWebshop().isIncidentLoaded ?? false
  const { loading: loadBookings, bookings } = useBookings()
  const { currentCartId: activeSalesorderId } = useCarts()
  const { open } = useDialog('salesorderDialog') as Dialogs['salesorderDialog']
  const { disableSelectionDialog } = useFilter()
  const m = messages.pages.shop.overview.salesorderSelectionDialog

  useEffect(() => {
    if (activeSalesorderId === "0") {
        mergeDialog('salesorderDialog', {
          open: !disableSelectionDialog
        })
      }
  }, [])

  const onCloseDialog = () => {
    mergeDialog('salesorderDialog', { open: false, })
  }

  const handleChangeSelect = (e: SelectChangeEvent<unknown>) => {
    const id = e?.target?.value as string
    mergeCarts({
      currentCartId: id,
    })
    if(id !== "0") {
      mergeDialog('salesorderDialog', { open: false })
    }
  }

  let formatedBookings = bookings
  .filter(filterSelecableBookings)
  .sort((a, b) => b.name.localeCompare(a.name))
  .map(booking => {
    const standhall = booking.standhall ? m.standhall + booking.standhall : ""
    const standnumber = booking.standnumber ? m.standnumber + booking.standnumber : ""
    return {
      key: booking.id,
      value: `${booking.name} | ${booking.exhibitor}${standhall}${standnumber}`,
    }
  })

  if (formatedBookings?.length > 0) {
    formatedBookings = [
      {
        key: "0",
        value: `-- ${m.title} --`,
      },
      ...formatedBookings,
    ]
  }

  const redirectAccountForm = useMemo(
    () =>
      pages?.data?.nodes?.filter(
        (page) =>
          page?.language?.slug === locale &&
          page?.template?.templateName === "Account Overview Template",
      )?.[0],
    [pages, locale],
  )

  let firstRowButtons: ButtonProps[] = [
    {
      title: m.buttons.permission,
      disabled: loadBookings || formatedBookings?.length < 1,
      onClick: async () => {
        if (isAuthenticated) {
          mergeBookingDetailPermissions({
            open: true,
            permissionTableUser: "Representative",
          })
        } else {
          router.push(
            locale === "de" ? "/de/registrierung" : "/en/registration",
          )
        }
      },
    },
    {
      title: m.buttons.request,
      disabled: false,
      onClick: async () => {
        if (isAuthenticated) {
          mergeDialog('salesorderRequestDialog', { open: true })
        } else {
          router.push(
            locale === "de" ? "/de/registrierung" : "/en/registration",
          )
        }
      },
    }
  ]

  firstRowButtons = authorizationRequested
  ? firstRowButtons
  : [
    ...firstRowButtons,
    {
      title: m.buttons.authorize,
      disabled: false,
      onClick: async () => {
        mergeAccount({ webshop: { shouldActivateAccountWebshopTab: true } })
        await router.push(redirectAccountForm?.uri as string)
      },
    },
  ]
  let secondRowButtons: ButtonProps[] = [
    {
      title: m.buttons.disable,
      variant: "text",
      className: bem("disableButton"),
      onClick: async () => {
        mergeFilter({ disableSelectionDialog: true, })
      },
    },
    {
      title: m.buttons.jumpOver,
      variant: "text",
    },
  ] as ButtonProps[]

  return (
    <Dialog
      closeOnClick
      firstRowButtons={firstRowButtons}
      open={open}
      secondRowButtons={secondRowButtons}
      style={style}
      title={m.title}
      onClose={onCloseDialog}
    >
      <div className={bem()}>
        <Alert className={bem("info")} severity="info">
          <p>{m.info}</p>
        </Alert>
        <p className={bem("desc")}>{m.desc}</p>
        {loadBookings && isAuthenticated
						? <CircularProgress className={bem("loading")} />
            : <>
                {formatedBookings?.length > 0 ? (
                <Select
                  items={formatedBookings}
                  title={m.input}
                  value={activeSalesorderId}
                  onChange={handleChangeSelect}
                />
              ) : (
                <p className={bem("helperText")}>{m.noData}</p>
              )}
            </>
        }
      </div>
    </Dialog>
  )
}
