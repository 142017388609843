import HighlightOff from "@mui/icons-material/HighlightOff"
import LinearProgress from "@mui/material/LinearProgress"
import { SelectChangeEvent } from "@mui/material/Select"

import React, { FC, useEffect, useState, useMemo } from "react"

import { Select } from "src/components/common/select"
import { Table, TableData } from "src/components/common/table"

import { filterSelecableBookings } from "src/components/core/header"
import { create } from "src/helpers/bem"

import {
  getPermissionBySalesorderId,
  deletePermission,
} from "src/queriesXRM/permission"
import { useBooking } from "src/states/bookingDetail"
import {
  useBookingDetailPermissions,
  mergeBookingDetailPermissions,
  removeBookingDetailPermission,
} from "src/states/bookingDetailPermissions"

import { useBookings } from "src/states/bookings"
import { TranslationMessages } from "src/translations"

import styles from "./PermissionTable.module.scss"
import { PermissionTableActions } from "./PermissionTableActions"

const bem = create(styles, "PermissionTable")

export type PermissionTableProps = {
  messages: TranslationMessages
}

export const PermissionTable: FC<PermissionTableProps> = ({ messages }) => {
  const m = messages.pages.booking.detail.actions.permissions
  const [loading, setLoading] = useState(true)
  const { booking } = useBooking()
  const { bookings } = useBookings()
  const { permissions, permissionTableUser, currentSalesorderId, open } =
    useBookingDetailPermissions()

  const formattedBookings = bookings
    .filter(filterSelecableBookings)
    .sort((a, b) => b.name.localeCompare(a.name))
    .map(booking => {
      const standhall = booking.standhall ? m.selectBookingStandhall + booking.standhall : ""
      const standnumber = booking.standnumber ? m.selectBookingStandnumber + booking.standnumber : ""
      return {
        key: booking.id,
        value: `${booking.name!} | ${booking.exhibitor}${standhall}${standnumber}`,
      }
    })

  const getPermissions = async () => {
    await getPermissionBySalesorderId(currentSalesorderId).then((res) => {
      if (Array.isArray(res)) {
        if (permissionTableUser === "MainExhibitor") {
          mergeBookingDetailPermissions({
            permissions: res,
          })
        } else {
          mergeBookingDetailPermissions({
            permissions: res.filter(
              (permission) => permission.role != "Administrator",
            ),
          })
        }
      }
    })
  }

  useEffect(() => {
    if (open) {
      if (permissionTableUser === "MainExhibitor") {
        mergeBookingDetailPermissions({
          currentSalesorderId: booking?.id as string,
        })
      } else {
        mergeBookingDetailPermissions({
          currentSalesorderId: formattedBookings[0].key as string,
        })
      }
    }
  }, [open])

  useEffect(() => {
    setLoading(true)
    getPermissions().finally(() => {
      setLoading(false)
    })
  }, [currentSalesorderId])

  const handleSearchTable = (value: string, data: TableData[]) => {
    const Value = value.toLowerCase()
    return data.filter((item) => {
      if (!Value) return true
      if (
        item.name?.toLowerCase()?.includes(Value) ||
        (item.email as string)?.toLowerCase()?.includes(Value) ||
        (item.emailForeignAccount as string)?.toLowerCase()?.includes(Value) ||
        (typeof item?.name === "string" &&
          item?.name?.toLowerCase()?.includes(Value))
      ) {
        return true
      }
    })
  }

  const handleChangeSelect = (e: SelectChangeEvent<unknown>) => {
    const id = e?.target?.value as string
    mergeBookingDetailPermissions({
      currentSalesorderId: id,
    })
  }

  const handleDeleteRole = async (e: TableData) => {
    setLoading(true)
    await deletePermission(e.id as string)
      .then(() => {
        removeBookingDetailPermission(e.id as string)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const formatedPermissions = useMemo(
    () =>
      permissions.map((el) => ({
        id: el.id,
        name: el.name ?? "-",
        email: el.email,
        role: el.role,
        emailForeignAccount: el.emailForeignAccount ?? "-",
      })),
    [permissions],
  ) as TableData[]

  if (loading) {
    return <LinearProgress color="primary" />
  }
  return (
    <div className={bem()}>
      <PermissionTableActions messages={messages} />
      {permissionTableUser === "Representative" && (
        <div className={bem("booking__select")}>
          <Select
            helperText={m.selectBookingHelperText}
            items={formattedBookings}
            label={m.selectBookingLabel}
            value={currentSalesorderId}
            onChange={handleChangeSelect}
          />
        </div>
      )}
      {formatedPermissions && formatedPermissions.length > 0 ? (
        <Table
          showAvatar
          cellTranslations={m.table}
          data={formatedPermissions}
          handleSearch={handleSearchTable}
          messages={messages}
          order="desc"
          orderBy="name"
          iconActions={[
            {
              icon: <HighlightOff />,
              color: "error",
              onClick: handleDeleteRole,
            },
          ]}
        />
      ) : (
        <p className={bem("nodata")}>{m.noData}</p>
      )}
    </div>
  )
}
