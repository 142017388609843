import { createSimpleGlobalState } from "src/hooks/simpleGlobalState"

import { xRMApiNomenclature } from "src/types/xRM"

const { getValue, setValue, useValue } = createSimpleGlobalState<
  xRMApiNomenclature[]
>([], "nomenclature")

export {
  getValue as getNomenclature,
  setValue as setNomenclature,
  useValue as useNomenclature,
}
