import xrmApi from "src/services/xrmApi";
import { mergeAccountFormWebshop, setIncident } from "src/states/accountFormWebshop";
import { xRMApiAccount, xRMApiIncident, xRMApiPurchaseorder } from "src/types/xRM";

export const postIncident = (data: xRMApiIncident) => xrmApi
	.post('/incident', data)
	.then(res => res?.data ?? null)

export const postIncidentAuthorization = (data: xRMApiAccount) => xrmApi
	.post('/incident/authorization', data)
	.then((res) => {
		if (res?.data) {
			setIncident(res?.data)
		}
		return res
	  })

export const fetchWebshopIncidents = (): Promise<xRMApiIncident[]> => xrmApi
	.get(`/incident`)
	.then(res => res?.data ?? [])

export const fetchIncidentAuthorization = async (): Promise<xRMApiIncident> => xrmApi
	.get(`/incident/authorization`)
	.then((res) => {
		setIncident(res?.data)
		if (!res?.data) {
			return
		}
		Object.keys(res.data).map((key) => {
			mergeAccountFormWebshop(
				0,
				{
				value: res.data?.[key] ?? "",
				},
				key,
			)
			if(key?.toLowerCase().includes("client"))
			{
				mergeAccountFormWebshop(
				1,
				{
					value: res.data?.[key] ?? "",
				},
				key,
				)
			}
		})
		return res.data
	  })

export const fetchWebshopIncidentsByOrder = (order: Pick<xRMApiPurchaseorder, 'id'>): Promise<xRMApiIncident[]> => xrmApi
	.get(`/incident?purchaseorderId=${order.id}`)
	.then(res => res?.data ?? [])
