import React, { FC, useEffect, useState } from "react"

import { Stepper } from "src/components/common/stepper/Stepper"

import { create } from "src/helpers/bem"

import { TranslationMessages } from "src/translations"

import styles from "./AdvancedForm.module.scss"
import { AdvancedFormFieldProps } from "./AdvancedFormField"
import { AdvancedFormFields } from "./AdvancedFormFields"
import { AdvancedFormStep, AdvancedFormStepProps } from "./AdvancedFormStep"
import { AdvancedFormTitle } from "./AdvancedFormTitle"

const bem = create(styles, "AdvancedForm")
const productNameFields = [
  "client_requestedproducts_standarea",
  "client_requestedproducts_category1",
  "client_requestedproducts_category2",
]
const clientValueFieldNames = [
  "client_requestedareacalculation",
  "client_requestedamount",
]

export interface AdvancedFormCondition {
  [p: string]: boolean
}

export interface AdvancedForms {
  id: string
  name?: string
  label?: string
  description?: string
  position?: number
  helperText?: string
  fields: AdvancedFormFieldProps[]
  conditions?: AdvancedFormCondition
  defaultConditions?: AdvancedFormCondition
}

export type AdvancedFormButtons = {
  backButtonLabel?: string
  nextButtonLabel?: string
  draftButtonLabel?: string
  finishButtonLabel?: string
}

export type AdvancedFormData =
  | ReadonlyArray<AdvancedFormStepProps>
  | ReadonlyArray<AdvancedForms>

export type AdvancedFormProps = {
  activeStep?: number
  id?: string
  stepLabelsClickable?: boolean
  formDisabled?: boolean
  data: AdvancedFormData
  messages?: TranslationMessages
  formMessages?: any
  buttons?: AdvancedFormButtons
  handleChangeActiveStep?: (activeStep: number) => void
  handleMergeField: (
    index: number,
    items: AdvancedFormFieldProps,
    name: string | null,
  ) => void
  handleReset?: () => void
  onSendForm?: (status?: "Draft") => void
}

export const AdvancedForm: FC<AdvancedFormProps> = ({
  activeStep,
  id,
  stepLabelsClickable,
  formDisabled,
  data,
  messages,
  formMessages,
  buttons,
  handleChangeActiveStep,
  handleMergeField,
  handleReset,
  onSendForm,
}) => {
  const [activeFormsValidAsDraft, setActiveFormsValidAsDraft] =
    useState<boolean>(false)
  const [activeFormsValid, setActiveFormsValid] = useState<boolean>(false)

  const validateFields = () => {
    let fields
    if (activeStep !== undefined) {
      fields = (data?.[activeStep] as AdvancedFormStepProps)?.forms?.map(
        (form) =>
          form?.fields.map((el) => ({
            ...el,
            value:
              el?.value instanceof Array && el?.value?.length === 0
                ? ""
                : el?.value,
            required:
              form?.conditions?.display === false ||
              el?.conditions?.display === false
                ? false
                : el.required,
          })),
      )
    } else {
      fields = (data as AdvancedForms[])?.map((form) => {
        return form?.fields.map((el) => ({
          ...el,
          value:
            el?.value instanceof Array && el?.value?.length === 0
              ? ""
              : el?.value,
          required:
            form?.conditions?.display === false ||
            el?.conditions?.display === false
              ? false
              : el.required,
        }))
      })
    }
    const mergedResult = ([] as AdvancedFormFieldProps[]).concat.apply(
      [],
      fields,
    )
    const filteredFieldsForDraft = mergedResult.filter((el) => el?.errorText)
    setActiveFormsValidAsDraft(filteredFieldsForDraft.length > 0)
    const filteredFieldsForSend = mergedResult.filter(
      (el) => (el?.required && !el?.value) || el?.errorText,
    )
    setActiveFormsValid(filteredFieldsForSend.length > 0)
  }

  useEffect(() => {
    validateFields()
  }, [data])

  const updateFieldState = (value: AdvancedFormFieldProps, name?: string) => {
    const Name = name?.split("/")
    if (Name) {
      let formIndex
      if (activeStep !== undefined) {
        formIndex = (
          data[activeStep] as AdvancedFormStepProps
        )?.forms.findIndex((el) => el.id === Name[0])
      } else {
        formIndex = (data as AdvancedForms[]).findIndex(
          (el) => el.id === Name[0],
        )
      }
      var fieldNameIsProduct = productNameFields.includes(Name?.[2])
      if (fieldNameIsProduct && activeStep !== undefined) {
        const _formIndex = formIndex + 1
        clientValueFieldNames.forEach((fieldName, fieldNameIndex) => {
          const valueFieldNameIndex = _formIndex + fieldNameIndex
          const fieldToClear = (
            data[activeStep] as AdvancedFormStepProps
          )?.forms?.[valueFieldNameIndex]?.fields?.find(
            (el) => el.field === fieldName,
          )
          if (fieldToClear !== undefined) {
            handleMergeField(
              valueFieldNameIndex,
              {
                value: "",
                errorText: undefined,
              },
              fieldToClear?.id ?? fieldToClear?.name ?? "",
            )
          }
        })
      }
      // Stand calculator
      if (
        (Name?.[2] === "mb_requestedareadepthmin_float" &&
          activeStep !== undefined) ||
        (Name?.[2] === "mb_requestedareawidthmin_float" &&
          activeStep !== undefined)
      ) {
        const totalField = (data[activeStep] as AdvancedFormStepProps)?.forms?.[
          formIndex
        ]?.fields?.find((el) => el.field === "client_calculatedareasize")
        let complementaryField = "mb_requestedareawidthmin_float"
        if (Name?.[2] === "mb_requestedareawidthmin_float") {
          complementaryField = "mb_requestedareadepthmin_float"
        }
        const widthOrDepth = (
          data[activeStep] as AdvancedFormStepProps
        )?.forms?.[formIndex]?.fields?.find(
          (el) => el.field === complementaryField,
        )?.value
        let totalValue = Number(value?.value) * Number(widthOrDepth)
        let errorText
        if (!!totalField?.min && totalValue < totalField?.min) {
          errorText = `${messages?.components.common.fields.inputField.numericMin1} ${totalField.min} ${messages?.components.common.fields.inputField.numericMin2}`
        }
        if (!!totalField?.max && totalValue > totalField?.max) {
          errorText = `${messages?.components.common.fields.inputField.numericMax1} ${totalField.max} ${messages?.components.common.fields.inputField.numericMax2}`
        }
        if (!isNaN(totalValue)) {
          handleMergeField(
            formIndex,
            {
              value: totalValue.toString(),
              errorText,
            },
            totalField?.id ?? totalField?.name ?? "",
          )
        }
      }
      return handleMergeField(formIndex, value, Name[1])
    }
  }

  const handleBackStep = () => {
    handleChangeActiveStep?.((activeStep ?? 0) - 1)
  }

  const handleNextStep = async () => {
    if (data && data?.length - 1 === activeStep) {
      onSendForm?.()
      handleReset?.()
      return
    }
    handleChangeActiveStep?.((activeStep ?? 0) + 1)
  }

  const handleSaveDraft = async () => {
    onSendForm?.("Draft")
    return
  }

  if (activeStep === undefined) {
    return (
      <>
        {(data as AdvancedForms[])?.map((form) => (
          <div key={form.id} className={bem("form")}>
            <AdvancedFormTitle
              activeStep={activeStep}
              data={data}
              form={form}
              formMessages={formMessages}
            />
            <AdvancedFormFields
              activeStep={activeStep}
              data={data}
              fields={form?.fields}
              formDisabled={formDisabled}
              formId={form?.id}
              formMessages={formMessages}
              id={id}
              messages={messages}
              updateFieldState={updateFieldState}
            />
          </div>
        ))}
      </>
    )
  }

  let ButtonsRight = [
    {
      label:
        activeStep === data?.length - 1
          ? buttons?.finishButtonLabel ?? ""
          : buttons?.nextButtonLabel ?? "",
      disabled: activeFormsValid,
      onClick: handleNextStep,
    },
  ]
  if (buttons?.draftButtonLabel) {
    ButtonsRight = [
      {
        label: buttons?.draftButtonLabel ?? "",
        disabled: activeFormsValidAsDraft,
        onClick: handleSaveDraft,
      },
      ...ButtonsRight,
    ]
  }
  return (
    <Stepper
      buttonsRight={ButtonsRight}
      className={bem()}
      index={activeStep ?? 0}
      buttonsLeft={[
        {
          label: buttons?.backButtonLabel ?? "",
          disabled: activeStep === 0,
          onClick: handleBackStep,
        },
      ]}
      steps={
        data?.map((el, i) => ({
          title: {
            text:
              el?.name ??
              el?.label ??
              (formMessages as any)?.[el?.id]?.title ??
              "",
            onClick:
              stepLabelsClickable && i < activeStep
                ? () => handleChangeActiveStep?.(i)
                : undefined,
          },
        })) ?? []
      }
    >
      <AdvancedFormStep
        activeStep={activeStep}
        data={data}
        formDisabled={formDisabled}
        formMessages={formMessages}
        id={id}
        messages={messages}
        updateFieldState={updateFieldState}
      />
    </Stepper>
  )
}
