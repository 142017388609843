import MatCard from "@mui/material/Card"
import { CardProps as MatCardProps } from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"

import React, { FC, memo } from "react"

import { create } from "src/helpers/bem"

import styles from "./Card.module.scss"

const bem = create(styles, "Card")

export type CardSizes = "small" | "large"

export type CardClasses = {
  root?: string
  content?: string
}

export type CardProps = {
  classNames?: CardClasses
  size?: CardSizes
} & MatCardProps

export const Card: FC<CardProps> = memo(({ classNames, children }) => (
  <MatCard
    className={bem(undefined, undefined, classNames?.root)}
    sx={{ minWidth: 275 }}
  >
    <CardContent className={bem("content", undefined, classNames?.content)}>
      {children}
    </CardContent>
  </MatCard>
))

Card.displayName = "Card"
