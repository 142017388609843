import IntlMessageFormat from "intl-messageformat"
import { useRouter } from "next/router"
import React, { createContext, FC, ReactNode, useContext } from "react"

import { TranslationMessages } from "../translations"

const Context = createContext<TranslationMessages | null>(null)

export type FormatMessage = (
  message: string,
  values?: Record<string, string | number | Date>,
) => string

export type TranslationFeatures = {
  locale?: string | false
  messages: TranslationMessages
  formatMessage: FormatMessage
}

export type TranslationHook = () => TranslationFeatures

export const useTranslation: TranslationHook = () => {
  const { locale } = useRouter()
  const messages = useContext(Context) as TranslationMessages

  const formatMessage: FormatMessage = (message, values = {}) => {
    const formatted = new IntlMessageFormat(message, locale).format(values)
    if (Array.isArray(formatted)) {
      return formatted[0].toString()
    }

    return formatted.toString()
  }

  return { locale, messages, formatMessage }
}

export type TranslationProviderProps = {
  messages: TranslationMessages
  children?: ReactNode
}

export const TranslationProvider: FC<TranslationProviderProps> = ({
  children,
  messages,
}) => {
  const { Provider } = Context

  return <Provider value={messages}>{children}</Provider>
}
