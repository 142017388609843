import { SetPartialStateAction, useHookstate } from "@hookstate/core"

import { createAspState } from "./createAspState"

export interface BookingDetailPlacementReject {
  open: boolean
  id?: string
  message?: string
}

export const defaultState = {
  open: false,
} as BookingDetailPlacementReject

const state = createAspState<BookingDetailPlacementReject>(
  defaultState,
  "booking-detail-placement-reject",
)

/**
 * Merges the state with new data
 * @param items the items of the state
 */
export const mergeBookingDetailPlacementReject = (
  items: SetPartialStateAction<BookingDetailPlacementReject>,
) => state.merge(items)

/**
 * Overwrites the state with new data
 * @param items the items of the state
 */
export const setBookingDetailPlacementReject = (
  items: BookingDetailPlacementReject,
) => state.set(items)

/**
 * Resets the complete co-exhibitor invite state
 */
export const resetBookingDetailPlacementReject = () => state.set(defaultState)

/**
 * Returns complete BookingDetailPlacementReject added to the state.
 * @returns the BookingDetailPlacementReject
 */
export const getBookingDetailPlacementReject = () => state.get()

/**
 * React hook to receive complete BookingDetailPlacementReject from the state.
 * @returns the BookingDetailPlacementReject
 */
export const useBookingDetailPlacementReject = () => {
  return useHookstate(state).get()
}
