import xrmApi from "src/services/xrmApi"

import { xRMApiPlacementRequest } from "src/types/xRM"

export const getPlacements = async (id?: string | null) => {
  if (!id) {
    return null
  }
  return xrmApi.get(`/placement/${id}`).then(async (res) => {
    if (res?.data) {
      return res.data
    }
    return null
  })
}

export const postPlacement = async (data: xRMApiPlacementRequest) => {
  if (!data?.id) {
    return null
  }
  return xrmApi.post("/placement", data).then(async (res) => {
    if (res?.data) {
      return res.data
    }
    return null
  })
}
