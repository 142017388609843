import { createSimpleGlobalState } from "src/hooks/simpleGlobalState"
import { xRMApiAccount, xRMApiPermission } from "src/types/xRM"

export type Account = xRMApiAccount & {
  permissions?: xRMApiPermission[]
  webshop?: {
    shouldActivateAccountWebshopTab?: boolean
  }
}

const { getValue, setValue, mergeValue, resetValue, useValue } =
  createSimpleGlobalState<Account>({}, "account")

export {
  getValue as getAccount,
  setValue as setAccount,
  mergeValue as mergeAccount,
  resetValue as resetAccount,
  useValue as useAccount,
}

/**
 * Flag if the user account is incomplete
 */
export const isAccountIncomplete = () => {
    return getValue().id === undefined
}
