import React, { FC } from "react"

import { Accordion } from "src/components/common/accordion"
import { DataTable, DataTableItems } from "src/components/common/dataTable"

import { create } from "src/helpers/bem"
import { useBooking } from "src/states/bookingDetail"

import { TranslationMessages } from "src/translations"

import { xRMApiStep } from "src/types/xRM"

import styles from "./BookingDetailInfo.module.scss"

const bem = create(styles, "BookingDetailInfo")

export type BookingDetailInfoProps = {
  messages: TranslationMessages
}

export const BookingDetailInfo: FC<BookingDetailInfoProps> = ({ messages }) => {
  const m = messages.pages.booking.detail.info
  const { detail } = useBooking()
  return (
    <div className={bem()}>
      {detail?.steps?.length > 0 ? (
        <div className={bem("accordion__wrapper")}>
          {(JSON.parse(JSON.stringify(detail?.steps)) as xRMApiStep[])?.map(
            (step, a) => {
              const formatedData = step?.forms?.map((el) => ({
                ...el,
                items: el.fields,
              }))
              return (
                <Accordion
                  key={step.id}
                  defaultExpanded={a === 0 ? true : false}
                  id={step.id ?? ""}
                  label={step?.label ?? (m as any)[step?.id ?? ""]?.title ?? ""}
                >
                  <DataTable
                    data={formatedData as DataTableItems[]}
                    translations={(m as any)[step?.id ?? ""]}
                  />
                </Accordion>
              )
            },
          )}
        </div>
      ) : null}
    </div>
  )
}
