import xrmApi from "src/services/xrmApi"

import { xRMApiPermission } from "src/types/xRM"

export const getPermission = async () => {
  return xrmApi.get("/permission").then(async (res) => {
    if (res?.data) {
      return res.data
    }
    return []
  })
}

export const getPermissionBySalesorderId = async (id?: string | null) => {
  if (!id) {
    return []
  }
  return xrmApi.get(`/permission?salesorderId=${id}`).then(async (res) => {
    if (res?.data) {
      return res.data
    }
    return []
  })
}

export const postPermission = async (data: xRMApiPermission) => {
  return xrmApi.post("/permission", data).then(async (res) => {
    if (res?.data) {
      return res.data
    }
    return null
  })
}

export const deletePermission = async (id?: string | null) => {
  if (!id) {
    return null
  }
  return xrmApi.delete(`/permission?id=${id}`).then(async (res) => {
    if (res?.data) {
      return res.data
    }
    return null
  })
}
