import LinearProgress from "@mui/material/LinearProgress"
import React, { FC, useEffect, useState, useMemo } from "react"

import {
  AdvancedForm,
  AdvancedFormStepProps,
} from "src/components/common/advancedForm"
import { Dialog } from "src/components/common/dialog"
import { BasicLayoutStyleProps } from "src/components/layouts/basic"
import { create } from "src/helpers/bem"

import { fetchCoExhibitors } from "src/helpers/fetchQueriesXRM"
import { formatFormData } from "src/helpers/form"
import { getFormById, postForm } from "src/queriesXRM/form"

import { useBooking } from "src/states/bookingDetail"
import {
  useCoExhibitorEdit,
  setCoExhibitorEditActiveStep,
  mergeCoExhibitorEditSteps,
  resetCoExhibitorEdit,
  mergeCoExhibitorEdit,
  mergeCoExhibitorEditField,
} from "src/states/coExhibitorEdit"
import { useLocale } from "src/states/locale"

import { TranslationMessages } from "src/translations"
import { xRMApiStep } from "src/types/xRM"

import styles from "./EditCoExhibitorDialog.module.scss"

const bem = create(styles, "EditCoExhibitorDialog")

export type EditCoExhibitorDialogProps = {
  messages: TranslationMessages
  style: BasicLayoutStyleProps
}

export const EditCoExhibitorDialog: FC<EditCoExhibitorDialogProps> = ({
  messages,
  style,
}) => {
  const [loading, setLoading] = useState(false)
  const m = messages.pages.booking.detail.actions.editCoExhibitor
  const formTranslations = m.form
  const { open, activeStep, activeUser, steps } = useCoExhibitorEdit()
  const { booking } = useBooking()
  const locale = useLocale()

  const loadForm = async () => {
    const form = await getFormById(activeUser?.id as string, "CoExhibitor")
    mergeCoExhibitorEditSteps(form ?? [])
    setLoading(false)
  }

  useEffect(() => {
    if (activeUser) {
      setLoading(true)
      mergeCoExhibitorEdit({
        activeStep: 0,
      })
      loadForm()
    }
  }, [locale, activeUser?.id])

  const onSendForm = async () => {
    setLoading(true)
    const data = await formatFormData(
      "CoExhibitor",
      activeStep,
      steps as ReadonlyArray<xRMApiStep>,
      booking?.id ?? undefined,
    )
    return postForm(data)
      .then(async () => {
        await fetchCoExhibitors(booking?.id ?? "")
        setLoading(false)
        resetCoExhibitorEdit()
      })
      .catch(() => {
        setLoading(false)
      })
  }

  return (
    <Dialog
      closeOnClick
      fullWidth
      maxWidth="xl"
      open={useMemo(() => open, [open]) ?? false}
      style={style}
      title={m.title}
      firstRowButtons={[
        {
          title: m.cancel,
          variant: "text",
        },
      ]}
      onClose={() =>
        mergeCoExhibitorEdit({
          open: !open,
          activeUser: null,
        })
      }
    >
      {loading || steps?.length === 0 ? (
        <LinearProgress color="primary" />
      ) : (
        <AdvancedForm
          stepLabelsClickable
          activeStep={activeStep}
          data={steps as AdvancedFormStepProps[]}
          formMessages={formTranslations}
          handleChangeActiveStep={setCoExhibitorEditActiveStep}
          handleMergeField={mergeCoExhibitorEditField}
          handleReset={resetCoExhibitorEdit}
          id={booking?.id ?? undefined}
          messages={messages}
          buttons={{
            backButtonLabel: formTranslations.buttons.back,
            nextButtonLabel: formTranslations.buttons.next,
            finishButtonLabel: formTranslations.buttons.finish,
          }}
          onSendForm={onSendForm}
        />
      )}
    </Dialog>
  )
}
