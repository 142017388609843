import LinearProgress from "@mui/material/LinearProgress"
import React, { FC, ChangeEvent, useState, useMemo } from "react"

import { Dialog } from "src/components/common/dialog"
import { InputField } from "src/components/common/inputfield"
import { BasicLayoutStyleProps } from "src/components/layouts/basic"

import { loadPlacements } from "src/components/services/booking/detail"
import { create } from "src/helpers/bem"
import { postPlacement } from "src/queriesXRM/placement"
import {
  mergeBookingDetailPlacementReject,
  useBookingDetailPlacementReject,
} from "src/states/bookingDetailPlacementReject"

import { TranslationMessages } from "src/translations"

import styles from "./RejectPlacementDialog.module.scss"

const bem = create(styles, "RejectPlacementDialog")

export type RejectPlacementDialogProps = {
  messages: TranslationMessages
  style: BasicLayoutStyleProps
}

export const RejectPlacementDialog: FC<RejectPlacementDialogProps> = ({
  messages,
  style,
}) => {
  const [loading, setLoading] = useState(false)
  const m = messages.pages.booking.detail.actions.placements.reject
  const { open, id, message } = useBookingDetailPlacementReject()

  const onReject = async () => {
    setLoading(true)
    await postPlacement({
      id,
      refusalReason: message,
      placementStatus: "Reject",
    })
    await loadPlacements()
    setLoading(false)
    mergeBookingDetailPlacementReject({
      open: false,
      message: undefined,
    })
  }

  const handleChangeField = (e: ChangeEvent<HTMLInputElement>) => {
    mergeBookingDetailPlacementReject({
      message: e.target.value,
    })
  }

  return (
    <Dialog
      closeOnClick
      fullWidth
      maxWidth="sm"
      open={useMemo(() => open, [open]) ?? false}
      style={style}
      title={m.title}
      titleClassName={bem("title")}
      firstRowButtons={[
        {
          title: m.buttons.reject,
          disabled: loading || !message,
          color: "error",
          onClick: () => onReject(),
        },
        {
          title: m.buttons.cancel,
          color: "inherit",
          variant: "text",
          onClick: () =>
            mergeBookingDetailPlacementReject({
              open: false,
              message: undefined,
            }),
        },
      ]}
    >
      {loading ? (
        <LinearProgress color="primary" />
      ) : (
        <>
          <p className={bem("content")}>{m.content}</p>
          <div className={bem("form")}>
            <div className={bem("form__field")}>
              <InputField
                multiline
                required
                label={m.form.message}
                name="message"
                rows={5}
                type="text"
                value={message}
                onChange={handleChangeField}
              />
            </div>
          </div>
        </>
      )}
    </Dialog>
  )
}
