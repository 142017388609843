import React, { FC } from "react"

import { Icon } from "src/components/common/icon"
import { create } from "src/helpers/bem"

import { useNavigation, setNavigation } from "src/states/navigation"

import { NavigationProps } from "src/types/SharedPageProps"

import styles from "./Navigation.module.scss"
import { NavigationDropdown } from "./NavigationDropdown"
import { NavigationList } from "./NavigationList"

const bem = create(styles, "Navigation")

export const Navigation: FC<NavigationProps> = ({ data }) => {
  const { visible, title, items } = useNavigation()
  if (!data?.menuItems || !visible) {
    return null
  }
  return (
    <nav className={bem()} data-cy="Navigation">
      <span
        className={bem("close")}
        onClick={() => setNavigation({ visible: false })}
      >
        <Icon className={bem("close__icon")} name="x" />
      </span>
      {items?.nodes && items?.nodes.length > 0 ? (
        <NavigationDropdown
          className={bem("dropdown")}
          nodes={items}
          title={title ?? ""}
        />
      ) : null}
      <NavigationList {...data?.menuItems} />
    </nav>
  )
}
