import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import IconButton from "@mui/material/IconButton"
import React, { FC, useState } from "react"

import { Button } from "src/components/common/button"
import { Radio, RadioOption } from "src/components/common/radio"
import { create } from "src/helpers/bem"

import { useBooking } from "src/states/bookingDetail"

import { TranslationMessages, fetchTranslationMessages } from "src/translations"

import styles from "./AddCoExhibitorEmailInvite.module.scss"

const bem = create(styles, "AddCoExhibitorEmailInvite")

export type AddCoExhibitorEmailInviteProps = {
  messages: TranslationMessages
}

export const AddCoExhibitorEmailInvite: FC<AddCoExhibitorEmailInviteProps> = ({
  messages,
}) => {
  const languages = [
    {
      key: "German",
      value: messages.components.core.header.languageSelect.de,
      disabled: false,
    },
    {
      key: "English",
      value: messages.components.core.header.languageSelect.en,
      disabled: false,
    },
  ] as RadioOption[]

  const [template, setTemplate] = useState("")
  const [templateLanguage, setTemplateLanguage] = useState("")
  const m = messages.pages.booking.detail.actions.addCoExhibitor.emailInvite
  const { booking } = useBooking()

  async function prepareTemplate(language: string) {
    // IMPORTANT: SET ADDITIONAL LANGUAGE IF XRM SUPPORTS A NEW LANGUAGE
    var startDate = new Date(`${booking?.exhibition?.startDate}`)
    var endDate = new Date(`${booking?.exhibition?.endDate}`)
    var str = ""
    switch (language) {
      case "German":
        str = (await fetchTranslationMessages("de")).pages.booking.detail
          .actions.addCoExhibitor.emailInvite.form.emailInvite.fields.template
        str = str.replace(
          "REGISTERURL",
          `${process.env.WEB_ORIGIN}/de/registrierung/`,
        )
        str = str.replace(
          "INVITELINK",
          `${process.env.WEB_ORIGIN}/de/mitausstelleranmeldung?invitationcode=${booking?.id}`,
        )
        str = str.replace("START", `${startDate.toLocaleDateString("de-DE")}`)
        str = str.replace("END", `${endDate.toLocaleDateString("de-DE")}`)
        str = str.replaceAll("TRADEFAIR-DE", `${booking?.exhibition?.name}`)
        break
      case "English":
        str = (await fetchTranslationMessages("en")).pages.booking.detail
          .actions.addCoExhibitor.emailInvite.form.emailInvite.fields.template
        str = str.replace(
          "REGISTERURL",
          `${process.env.WEB_ORIGIN}/en/registration/`,
        )
        str = str.replace(
          "INVITELINK",
          `${process.env.WEB_ORIGIN}/en/co-exhibitor-registration/?invitationcode=${booking?.id}`,
        )
        str = str.replace("START", `${startDate.toLocaleDateString("en-US")}`)
        str = str.replace("END", `${endDate.toLocaleDateString("en-US")}`)
        str = str.replaceAll(
          "TRADEFAIR-EN",
          `${booking?.exhibition?.englishName}`,
        )
        break
    }
    str = str.replace("COMPANY", `${booking?.clientAddressAccount1}`)
    str = str.replace("TRADEFAIRURL", `${booking?.exhibitionUrl}`)
    setTemplate(str)
  }

  const handleCopyClick = () => {
    if ("clipboard" in navigator) {
      navigator.clipboard.writeText(template)
    }
  }

  const handleChangeTemplateLanguage = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setTemplateLanguage(e.target.value)
    prepareTemplate(e.target.value)
  }

  const onCreateEmailTemplate = () => {
    window.location.href = `mailto:?body=${encodeURIComponent(template)}`
  }

  return (
    <div className={bem("description")}>
      {
        messages.pages.booking.detail.actions.addCoExhibitor.emailInvite
          .linkText
      }
      <br />
      <br />
      <Radio
        row
        items={languages}
        label={m.form.emailInvite.fields.language}
        value={templateLanguage}
        onChange={handleChangeTemplateLanguage}
      />
      <br />
      <br />
      <Button
        className={bem("button")}
        disabled={templateLanguage === ""}
        title={m?.button}
        onClick={onCreateEmailTemplate}
      />
      <IconButton
        aria-label="copy"
        color="primary"
        disabled={templateLanguage === ""}
        size="medium"
        onClick={handleCopyClick}
      >
        <ContentCopyIcon fontSize="small" />
      </IconButton>
    </div>
  )
}
