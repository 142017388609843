import FormControlLabel from "@mui/material/FormControlLabel"
import MatSwitch, { SwitchProps as MatSwitchProps } from "@mui/material/Switch"

import React, { FC } from "react"

export type SwitchProps = {
  label?: string
} & MatSwitchProps

export const Switch: FC<SwitchProps> = ({
  color = "primary",
  label,
  ...props
}) => (
  <FormControlLabel
    control={<MatSwitch {...props} color={color} />}
    label={label}
  />
)
