import { useHookstate } from "@hookstate/core"

import {
  AdvancedForms,
  AdvancedFormFieldProps,
} from "src/components/common/advancedForm"

import { createAspState } from "./createAspState"

export type AccountForm = AdvancedForms[]

export const defaultState = [
  {
    id: "personals",
    fields: [
      {
        position: 0,
        type: "languages",
        name: "language",
        required: true,
      },
      {
        position: 1,
        type: "academicTitle",
        name: "title",
      },
      {
        position: 2,
        type: "text",
        name: "firstName",
        required: true,
        max: 250,
      },
      {
        position: 3,
        type: "text",
        name: "lastName",
        required: true,
        max: 250,
      },
      {
        position: 4,
        type: "email",
        name: "email",
        required: true,
        max: 250,
      },
      {
        position: 5,
        type: "text",
        name: "position",
        max: 100,
      },
      {
        position: 6,
        type: "text",
        name: "department",
        max: 100,
      },
      {
        position: 7,
        type: "tel",
        name: "phone",
        required: true,
        max: 100,
      },
    ],
  },
  {
    id: "company",
    fields: [
      {
        position: 0,
        type: "text",
        name: "company1",
        required: true,
        max: 100,
      },
      {
        position: 1,
        type: "text",
        name: "companyStreet",
        required: true,
        max: 250,
      },
      {
        position: 3,
        type: "zip",
        name: "companyZipCode",
        required: true,
        max: 20,
      },
      {
        position: 4,
        type: "text",
        name: "companyCity",
        required: true,
        max: 100,
      },
      {
        position: 5,
        type: "country",
        name: "companyCountryCodeId",
        required: true,
      },
      {
        position: 6,
        type: "url",
        name: "companyWebsite",
        max: 100,
      },
      {
        position: 7,
        type: "email",
        name: "companyEmail",
        max: 100,
      },
      {
        position: 8,
        type: "companyType",
        name: "companyType",
      },
    ],
  },
] as AccountForm

const state = createAspState<AccountForm>(defaultState, "account-form")

/**
 * Changes complete state
 * @param data account data
 */
export const setAccountForm = (data: AccountForm) => state.set(data)

/**
 * Changes a existing field
 * @param index the index of the form
 * @param name the name of the field
 * @param items the changed attributes of the field
 */
export const mergeAccountForm = (
  index: number,
  items: AdvancedFormFieldProps,
  name: string | null,
) => {
  const fieldIndex = state
    .nested(index)
    .fields.get()
    ?.findIndex((e) => e.name === name)
  state
    .nested(index)
    .fields.nested(fieldIndex)
    ?.merge({
      ...items,
    })
}

/**
 * Resets the complete Account state
 */
export const resetAccountForm = () => state.set(defaultState)

/**
 * Returns complete Account added to the state.
 * @returns the Account
 */
export const getAccountForm = () => state.get()

/**
 * React hook to receive complete Account from the state.
 * @returns the Account
 */
export const useAccountForm = () => {
  return useHookstate(state).get()
}
