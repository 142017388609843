import xrmApi from "src/services/xrmApi"

import { setOptionset } from "src/states/optionsets"

export type OptionsetParam =
  | "mb_language"
  | "mb_academic_titles_optionset"
  | "mb_companytype_optionset"
  | "mb_ustdeclaration_optionset"

export const optionset = async (name: OptionsetParam) => {
  xrmApi.get(`/optionset?name=${name}`).then(async (res) => {
    if (res?.data) {
      switch (name) {
        case "mb_language":
          return setOptionset("languages", res?.data)
        case "mb_academic_titles_optionset":
          return setOptionset("academicTitles", res?.data)
        case "mb_companytype_optionset":
          return setOptionset("companyTypes", res?.data)
        case "mb_ustdeclaration_optionset":
          return setOptionset("ustDeclarations", res?.data)
        default:
          return null
      }
    }
  })
}
