import { AuthError, BrowserAuthError, InteractionRequiredAuthError } from "@azure/msal-browser"
import axios, { AxiosInstance, AxiosResponse, AxiosError } from "axios"

import { loginRequest, msalInstance } from "src/auth/msalConfig"
import { appInsights } from "src/logging/loggingConfig"

import { getLocale } from "src/states/locale"
import { NotificationVariant, addNotification } from "src/states/notifications"

export type CustomError = {
  status: number
  statusCode: number
  errorCode: number
  message: string
}

export type ErrorMessages = Record<string, string>

const xrmApi: AxiosInstance = axios.create({
  baseURL: process.env.XRM_API ?? "https://localhost:7231",
})

const loadLocale = async () => getLocale()

xrmApi.interceptors.request.use(async (config) => {
  const authResult = await acquireToken()
  const locale = await loadLocale()

  let language = "en-US"
  if (locale === "de") {
    language = "de-DE"
  }

  if (authResult) {
    msalInstance.setActiveAccount(authResult.account)
  }

  const bearer = `Bearer ${authResult?.accessToken}`

  if (config.headers) {
    config.headers.Authorization = bearer
    config.headers["Accept-Language"] = language
  }

  return config
})

const onFullfill = (response: AxiosResponse) => {
  if (["post", "put", "delete"].includes(response?.config?.method ?? "")) {
    response?.data?.messageCode !== "suppress" &&
      addNotification({
        variant: "success",
        code: response?.data?.messageCode?? undefined,
        autoclose: true,
      })
  }
  return response
}

const onReject = (error: Error | AxiosError) => {
  if (axios.isAxiosError(error)) {
    const data = error.response?.data as CustomError
    let showNotification = true
    let variant: NotificationVariant = "warning"
    if (data?.statusCode === 500 || data?.status === 500) {
      variant = "error"
    } else if (data?.statusCode === 404 || data?.status === 404) {
      appInsights.trackException({ exception: error })
      return
    } else if (data?.statusCode === 409 || data?.status === 409) {
      showNotification = false;
    }

    if(showNotification) {
      addNotification({
        variant,
        code: data?.message,
        autoclose: true,
      })
    }
  }

  appInsights.trackException({ exception: error })

  return Promise.reject(error)
}

const acquireToken = async () => {
  try {
    const account = msalInstance.getAllAccounts()[0]
    msalInstance.setActiveAccount(account)

    const tokenResponse = await msalInstance.acquireTokenSilent({
      ...loginRequest,
      account,
    })

    return tokenResponse
  } catch (err) {
    const error = err as AuthError
    if (err instanceof InteractionRequiredAuthError || error.errorMessage.includes("AADB2C90077")) {
      return await msalInstance.acquireTokenRedirect({
        ...loginRequest,
      })
    }

    if (err instanceof BrowserAuthError && error.message.includes("no_account_error")) {
      // ignore
      return null
    }

    console.error(err)
    return null
  }
}

xrmApi.interceptors.response.use(onFullfill, onReject)
export default xrmApi
