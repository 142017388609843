import React, { AriaAttributes, AriaRole, FC } from "react"

import { ReactComponent as barsSvg } from "src/assets/icons/bars.svg"
import { ReactComponent as circleArrowRight } from "src/assets/icons/circleArrowRight.svg"
import { ReactComponent as xmark } from "src/assets/icons/xmark.svg"

const iconsMap = {
  bars: barsSvg,
  circleArrowRight: circleArrowRight,
  x: xmark,
} as const

export type IconName = keyof typeof iconsMap

export type IconProps = {
  name?: IconName
  className?: string
  role?: AriaRole
} & AriaAttributes

export const Icon: FC<IconProps> = ({ name, ...props }) => {
  if (name === undefined) {
    return null
  }
  const Component = iconsMap[name]
  return <Component aria-hidden="true" role="presentation" {...props} />
}
