import { GET_COLOR_themeOptions_color } from "src/types/wordpress/generated/GET_COLOR"
import { TypographyFragment } from "src/types/wordpress/generated/TypographyFragment"

export const getColorVariables = (
  color: GET_COLOR_themeOptions_color | null | undefined,
) => {
  return {
    "--color-primary": color?.primary ?? "#0058a1",
    "--color-secondary": color?.secondary ?? "#0058a1",
    "--color-border": color?.border ?? "#0058a1",
    "--color-header-text": color?.text?.header ?? "#fff",
    "--color-navigation-text": color?.text?.navigation ?? "#fff",
    "--color-footer-text": color?.text?.footer ?? "#fff",
  }
}

export const getTypoVariables = (
  key: string,
  typography: TypographyFragment | null | undefined,
) => {
  return {
    [`--typography-${key}-font-family`]:
      typography?.fontFamily ?? typography?.backupFontFamily ?? "Arial",
    [`--typography-${key}-font-weight`]: typography?.fontWeight ?? 400,
    [`--typography-${key}-font-size`]: typography?.fontSize
      ? `${typography.fontSize}em`
      : "1.1em",
    [`--typography-${key}-font-style`]: typography?.fontStyle ?? "normal",
    [`--typography-${key}-line-height`]: typography?.lineHeight
      ? `${typography.lineHeight}em`
      : "1.28em",
    [`--typography-${key}-letter-spacing`]: typography?.letterSpacing
      ? `${typography.letterSpacing}em`
      : "0em",
    [`--typography-${key}-text-align`]: typography?.textAlign ?? "left",
    [`--typography-${key}-text-decoration`]:
      typography?.textDecoration ?? "none",
    [`--typography-${key}-text-transform`]: typography?.textTransform ?? "none",
    [`--typography-${key}-color`]: typography?.color ?? "#4d4d4d",
  }
}
