import { createSimpleGlobalState } from "src/hooks/simpleGlobalState"

const { getValue, setValue, mergeValue, resetValue, useValue } =
  createSimpleGlobalState<number>(0, "state-version")

export {
  getValue as getStateVersion,
  setValue as setStateVersion,
  mergeValue as mergeStateVersion,
  resetValue as resetStateVersion,
  useValue as useStateVersion,
}
