import { messages as defaultMessages } from "./messages/de"

export const LOCALES = ["de", "en"] as const
export const DEFAULT_LOCALE = "de"

export type Locale = (typeof LOCALES)[number]

export type TranslationMessages = typeof defaultMessages

type TranslationModule = {
  messages: TranslationMessages
  locale: Locale
}

export async function fetchTranslationMessages(
  locale: string | string[] = DEFAULT_LOCALE,
): Promise<TranslationMessages> {
  let currentLocale = locale ?? "de"
  if (locale !== "de" && locale !== "en") {
    currentLocale = "de"
  }
  const { messages } = (await import(
    `./messages/${currentLocale}`
  )) as TranslationModule
  return messages
}

export function isLocale(arg: Locale) {
  return LOCALES.some((element) => element === arg)
}
